import React, { useEffect, useState } from "react";
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import BlogForm from "./blogForm";
import axios from "axios";
import { base_url } from "../../constant";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: end;
  height: 100vh;
`;

const SidebarContainer = styled.div`
  width: 300px;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Content = styled.div`
  width: 80%;
  padding: 20px;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
`;

const Card = styled.div`
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:start;
  border-radius: 12px;
`;
const Button2 = styled.button`
  
  margin-bottom:20px;
  border: none;
  padding: 8px ;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background:none;
  &:hover {
   transform : scale(1.1);
  }
`;

const Button = styled.button`
  background-color: #0023B4;
  color: #fff;
  margin-bottom:20px;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Blog = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityData, setCityData] = useState([]);

  const accessToken = localStorage.getItem("x-access-token");
  const refreshToken = localStorage.getItem("x-refresh-token");
  // Fetch city data from the server
  const getCityData = async () => {
    try {
      const res = await axios.get(`${base_url}/blogs`, 
      );
      setCityData(res?.data?.data);
    } catch (error) {
      console.log("Error fetching city data:", error);
    }
  };

  // Fetch city data on component mount
  useEffect(() => {
    getCityData();
  }, []);

  // Toggle visibility of the BlogForm component
  const toggleFormVisibility = (city = null) => {
    setSelectedCity(city);
    setShowForm(!showForm);
    console.log("Edit icon clicked. Selected city:", city);
  };
const deleteBlogData = async(id) => {
  try {
    const res = await axios.delete(`${base_url}/blogs?id=${id}`,{headers: {
      "x-access-token": accessToken,
      "x-refresh-token": refreshToken,}
    });
    console.log(res.data);
    if(res?.data?.data){
      getCityData();
    }
  } catch (error) {
    console.log('Error deleting blog data:', error);
  }
}
  // Render city cards with edit button
  const renderCityCards = () => {
    return cityData.map((city) => (
      <Card key={city.id}>
        <h3>{city.name}</h3>
        <p>Heading: {city.heading}</p>
        <p>Category: {city.category}</p>
        <div >
          <Button2 ><img src="/edit.svg"  alt="edit"  onClick={() => toggleFormVisibility(city)}/></Button2>
          <Button2 style={{marginLeft:'30px'}}><img src="/trash-2 (1).svg" alt="delete"  onClick={() => deleteBlogData(city.id)}/></Button2>
        </div>
      </Card>
    ));
  };

  return (
    <Container>
      <SidebarContainer>
        <SideBar />
      </SidebarContainer>
      <Content>
        <FirstNavbar />
        {!showForm && (
          <Button onClick={() => toggleFormVisibility()}>Add Blogs</Button>
        )}
        {showForm && (
          <BlogForm
            initialData={{
              ...selectedCity,
            }}
            toggleFormVisibility={toggleFormVisibility}
          />
        )}
        {!showForm && <CardContainer>{renderCityCards()}</CardContainer>}
      </Content>
    </Container>
  );
};

export default Blog;
