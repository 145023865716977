import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Carousel, Button, Form, Table, Modal } from 'react-bootstrap';
import { base_url } from '../../constant';
import { useLocation } from 'react-router-dom';
import CancelBookingModal from './cancelBooking';


const BookingDetails = () => {
  const [bookingData, setBookingData] = useState({});
  const [tenants, setTenants] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [refundAmount, setRefundAmount] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [document, setDocument] = useState({
    document: '',
    documentName: ''
  });
  const [show, setShow] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [replacementDetails, setReplacementDetails] = useState([]);

  const handleClose = () => setShow(false);
  const handleCloseDoc = () => setShowDoc(false);

  const handleShow = (docName, doc) => {
    setDocument({
      document: doc,
      documentName: docName
    })
    setShow(true);

  }
  const handleShowDoc = () => setShowDoc(true);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [tenantId, setTenantId] = useState('');

  const [showServiceProof, setShowServiceProof] = useState(false);
  const [showFinalProof, setShowFinalProof] = useState(false);

  const handleShowServiceProof = () => setShowServiceProof(true);
  const handleShowFinalProof = () => setShowFinalProof(true);

  const [confirmBookingData, setConfirmBookingData] = useState({
    moveinDate: '',
    moveOutData: '',
    price: 0,
  });

  const getTenantDocuments = (tenantId) => {
    return documents.filter((doc) => doc.tentantsId === tenantId);
  };
  const getTenantName = (email) => {
    const tenant = tenants.find(tenant => tenant.email === email);
    return tenant ? tenant.name : 'Unknown'; // Default if tenant not found
  };

  // Function to render tenant name in payment table
  const renderTenantName = (email) => {
    const name = getTenantName(email);
    return <>{name}</>;
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const singleBookingData = async () => {
    try {
      const accessToken = localStorage.getItem('x-access-token');
      const refreshToken = localStorage.getItem('x-refresh-token');
      const res = await axios.get(`${base_url}/booking/getBookingSingle?id=${id}`, {
        headers: {
          'x-access-token': accessToken,
          'x-refresh-token': refreshToken,
        }
      });
      setBookingData(res?.data?.booking[0]);
      setTenants(res?.data?.tentants);
      setDocuments(res?.data?.document);
      setPaymentData(res?.data?.payment);
      setDocumentData(res?.data?.document);
      setReplacementDetails(res?.data?.replacementData[0]);
      const rd = []
      res?.data?.payment?.map((item) => { rd.push(0) });
      setRefundAmount(rd)
      setConfirmBookingData({
        moveinDate: res?.data?.booking[0].moveinDate,
        moveOutData: res?.data?.booking[0].moveOutData,
        price: parseInt(res?.data?.booking[0].monthlyRent),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelBooking = async () => {
    setShowCancelModal(true);
  };

  const handleCancelConfirm = async (cancelReason, cancelReasonDetails) => {
    try {
      const accessToken = localStorage.getItem('x-access-token');
      const refreshToken = localStorage.getItem('x-refresh-token');
      const response = await axios.post(`${base_url}/booking/cancelBookingAdmin?id=${id}`, {
        cancelReason,
        cancelReasonDetails,
      }, {
        headers: {
          'x-access-token': accessToken,
          'x-refresh-token': refreshToken,
        },
      });
      console.log(response?.status, "Status")
      console.log(response?.data)
      alert("Successfully Cancelled Booking");
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmBooking = async () => {
    try {
      const accessToken = localStorage.getItem('x-access-token');
      const refreshToken = localStorage.getItem('x-refresh-token');
      console.log(confirmBookingData)
      confirmBookingData.booking = id
      const response = await axios.post(`${base_url}/booking/confirmBooking`, confirmBookingData, {
        headers: {
          'x-access-token': accessToken,
          'x-refresh-token': refreshToken,
        },
      });
      alert('Successfully Confirmed Booking');
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDocumentVerification = async () => {
    try {
      const accessToken = localStorage.getItem('x-access-token');
      const refreshToken = localStorage.getItem('x-refresh-token');
      console.log(confirmBookingData)
      let payload = {};

      if (bookingData?.booking_status === 'document_verified') {
        payload.tenantId = tenantId;
      } else {
        console.log(tenantId);
        payload = {
          booking: id,
          moveOutData: confirmBookingData.moveOutData,
          moveinDate: confirmBookingData.moveinDate,
          status: 'document_verified',
          price: confirmBookingData.price,
        };
      }
      const response = await axios.post(`${base_url}/booking/verifyDocument`, payload, {
        headers: {
          'x-access-token': accessToken,
          'x-refresh-token': refreshToken,
        },
      });
      alert('Document Successfully Verified');
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdateBooking = async () => {
    try {
      const accessToken = localStorage.getItem('x-access-token');
      const refreshToken = localStorage.getItem('x-refresh-token');
      console.log(confirmBookingData)
      confirmBookingData.booking = id
      console.log(confirmBookingData, "Data")
      const response = await axios.post(`${base_url}/booking/updateCurrentBooking`, confirmBookingData, {
        headers: {
          'x-access-token': accessToken,
          'x-refresh-token': refreshToken,
        },
      });
      alert('Successfully Updated Booking');
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleOfflinePaymentRefund = async (payment, index) => {
    try {
      console.log(refundAmount[index], "Refund Amount")
      if (payment?.amount < refundAmount[index]) {
        alert("Amount cant be greater than actual amount")
      }
      else {
        const accessToken = localStorage.getItem('x-access-token');
        const refreshToken = localStorage.getItem('x-refresh-token');
        const res = await axios.post(`${base_url}/payment/refundOfflineAmount`, {
          id: payment?.id,
          refundAmount: refundAmount[index]
        },
          {
            headers: {
              'x-access-token': accessToken,
              'x-refresh-token': refreshToken,
            }
          })
        window.location.reload();
        console.log(res?.status, res?.data)
      }


    } catch (error) {
      console.log(error)
    }
  }
  const handleOnlinePaymentRefund = async (payment, index) => {
  }

  const handleRefundAmountFieldChange = (e, index) => {
    const newRefundAmount = [...refundAmount];
    newRefundAmount[index] = parseInt(e.target.value, 10) || 0; // Convert to integer or set to 0 if NaN
    setRefundAmount(newRefundAmount);
  };


  useEffect(() => {
    singleBookingData();
  }, [id]);


  return (
    <Container>
      <CancelBookingModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        onCancel={handleCancelConfirm}
      />
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>Booking Details</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Card.Title>{bookingData.name}</Card.Title>
                </Col>
                <Col onClick={handleCancelBooking}>
                  <Button className="btn btn-danger">Cancel Booking</Button>
                </Col>
                {bookingData.booking_status == 'service_fee_paid' && (
                  <Col>
                    <Button onClick={handleDocumentVerification}>Verify Document</Button>
                  </Col>
                )}
                {bookingData.booking_status == 'final_payment_done' && (
                  <Col>
                    <Button onClick={handleConfirmBooking}>Confirm Booking</Button>
                  </Col>
                )}

                {bookingData.booking_status == 'booked' && (
                  <Col>
                    <Button onClick={handleUpdateBooking}>Update Booking</Button>
                  </Col>
                )}
              </Row>
              <Card.Title>
                Current Status : <span>{bookingData.booking_status} </span>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {bookingData.buildingType} - {bookingData.bedroom} Bedrooms -{bookingData.bath} Bathrooms
              </Card.Subtitle>
              <Card.Text>{bookingData.locationDescription}</Card.Text>
              <ListGroup>
                <ListGroup.Item>
                  <strong>Area:</strong> {bookingData.area}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>City:</strong> {bookingData.city}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Country:</strong> {bookingData.country}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Monthly Rent:</strong> {parseInt(bookingData.monthlyRent)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Service Fee:</strong> {bookingData.serviceFee}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Move-in Date:</strong> {bookingData.moveinDate}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Move-out Date:</strong> {bookingData.moveOutData}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>Property Images</Card.Header>
            <Card.Body>
              <Carousel>
                {bookingData.photos?.map((photo, index) => (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100" src={photo} alt={`Slide ${index}`} width={100} height={300} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Documents</Card.Header>
            {/* <ListGroup>
              {documentData.map((tenant) => (
                <ListGroup.Item key={tenant.id}>

                  <ListGroup>

                        <strong>{tenant.documentName}:</strong>{' '}
                        <span><button onClick={() => handleShow(tenant.documentName,tenant.document)} style={{background:'none',border:'none',color:'blue',width:'fit-conent', }}>View Document</button></span>
        
                  </ListGroup>
                </ListGroup.Item>
              ))}
              {bookingData.totaltenants > 1 && <button>Verify Document</button>}
            </ListGroup> */}
            <div className='flex items-center justify-between p-3'>
              <div>
                {documentData.map((tenant) => (
                  <div key={tenant.id} className='flex flex-col item-center'>
                    <div>
                      <strong>{tenant.documentName}:</strong>{' '}
                      <span><button onClick={() => handleShow(tenant.documentName, tenant.document)} style={{ background: 'none', border: 'none', color: 'blue', width: 'fit-conent', }}>View Document</button></span>
                    </div>
                  </div>
                ))}
              </div>
              <div>{bookingData.totaltenants > 1 && <button variant='primary' onClick={handleShowDoc}>Verify Document</button>}</div>
              {showDoc && <Modal show={showDoc} onHide={handleCloseDoc}>
                <Modal.Header closeButton>
                  <Modal.Title>tents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <select value={tenantId} onChange={(e) => setTenantId(e.target.value)}>
                    {tenants.map(data => {
                      return (
                        <option value={data.id}>
                          {data.name}
                        </option>)
                    })}
                  </select>
                  <button onClick={handleDocumentVerification} style={{ padding: '10px', margin: '5px', background: 'blue', color: 'white', border: 'none', borderRadius: '5px', width: '90px', display: 'block' }}>Submit</button>
                </Modal.Body>
              </Modal>}
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <Card>
            <Card.Header>Payment</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Tenant Name</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Method</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Mannual Payment Proof</th>
                    <th>Refund</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentData.map((payment, index) => (
                    <tr key={index}>
                      <td>{renderTenantName(payment?.paidBy)}</td>
                      <td>{payment?.type}</td>
                      <td>{payment?.createdAt.slice(0, 10).split('-').reverse().join('/')}</td>
                      <td>{payment?.method}</td>
                      <td>{payment?.amount}</td>
                      <td>{payment?.status}</td>
                      <td>

                        <span><button onClick={() => handleShow('', payment.paymentProof)} style={{ background: 'none', border: 'none', color: 'blue', width: 'fit-conent', }}>View Document</button></span>

                      </td>
                      {payment?.status === 'refunded' ?
                        <td>
                          <p><strong>Amount :</strong> {payment?.refundAmount}</p>
                          <p><strong>Refund Date :</strong> {payment?.refundedDate?.slice(0, 10).split('-').reverse().join('/')}</p>
                        </td> :
                        <td>
                          <Form>
                            <Form.Control
                              type="number"
                              placeholder="Enter refund amount"
                              min="0"
                              value={refundAmount[index]}
                              onChange={(e) => handleRefundAmountFieldChange(e, index)}
                              max={payment?.amount}
                            />
                            <Button
                              variant="danger"
                              className="mt-2"
                              onClick={() => {
                                payment?.method === 'offline'
                                  ? handleOfflinePaymentRefund(payment, index)
                                  : handleOnlinePaymentRefund(payment, index);
                              }}
                            >
                              Refund
                            </Button>
                          </Form>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        {(bookingData.booking_status == "service_fee_paid" || bookingData.booking_status == "final_payment_done" || (bookingData.booking_status == "booked" && new Date(bookingData.moveOutData) >= new Date())) &&
          <Col md={3}>
            <Card>
              <Card.Header>Confirm Booking</Card.Header>
              <Card.Body>
                <Form>
                  <Form.Group controlId="moveinDate">
                    <Form.Label>Move-in Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="moveinDate"
                      value={confirmBookingData.moveinDate}
                      onChange={(e) =>
                        setConfirmBookingData({
                          ...confirmBookingData,
                          moveinDate: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group controlId="moveOutData">
                    <Form.Label>Move-out Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="moveOutData"
                      value={confirmBookingData.moveOutData}
                      onChange={(e) =>
                        setConfirmBookingData({
                          ...confirmBookingData,
                          moveOutData: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group controlId="price">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      name="price"
                      value={confirmBookingData.price}
                      onChange={(e) =>
                        setConfirmBookingData({
                          ...confirmBookingData,
                          price: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>}
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Header>Tenants</Card.Header>
            <ListGroup>
              {tenants.map((tenant) => (
                <ListGroup.Item key={tenant.id}>
                  <h5>{tenant.name}</h5>
                  <p>
                    <strong>Reason:</strong> {tenant.reason}
                  </p>
                  <p>
                    <strong>Email:</strong> {tenant.email}
                  </p>
                  <p>
                    <strong>Passport:</strong> {tenant.passport}
                  </p>
                  <p>
                    <strong>LOI Signed:</strong>
                    <span onClick={tenant.loiUploaded ? () => window.open(tenant?.loi) : null} disabled={!tenant.loiUploaded}>
                      {tenant.loiUploaded ? 'View LOI' : 'Not Signed'}
                    </span>
                  </p>
                  <p>
                    <strong>Contract Signed:</strong>
                    <span onClick={tenant.contract ? () => window.open(tenant?.contract) : null} disabled={!tenant.contract}>
                      {tenant.contract ? 'View Contract' : 'Not Signed'}
                    </span>
                  </p>

                  <p>
                    <strong>Document Status:</strong> {tenant.documentStatus}
                  </p>
                  <p>
                    <strong>Documents:</strong>
                  </p>
                  <ListGroup>
                    {getTenantDocuments(tenant.id).map((doc) => (
                      <ListGroup.Item key={doc.id}>

                        <strong>{doc.documentName}:</strong>{' '}
                        <span><button onClick={() => handleShow(doc.documentName, doc.document)} style={{ background: 'none', border: 'none', color: 'blue', width: 'fit-conent', }}>View Document</button></span>

                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>

      <Card.Header>Replacement Details:</Card.Header>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Passport</th>
            <th>Reason</th>
            <th>BookingID</th>
            <th>Replace From</th>
            <th>Status</th>
            <th>Service Payment Proof</th>
            <th>Final Payment Proof</th>
          </tr>
        </thead>
        <tbody>
          {replacementDetails ? (
            <tr>
              <td>{replacementDetails.name}</td>
              <td>{replacementDetails.email}</td>
              <td>{replacementDetails.passport}</td>
              <td>{replacementDetails.reason}</td>
              <td>{bookingData.bookingUniqueId}</td>
              <td>{replacementDetails.replaceFromTenant?.name}</td>
              <td>{replacementDetails.bookingStatus}</td>
              <td><Button onClick={handleShowServiceProof}>View</Button></td>
              <td><Button onClick={handleShowFinalProof}>View</Button></td>
            </tr>
          ) : (
            <tr>
              <td colSpan="9">No replacement details available</td>
            </tr>
          )}
        </tbody>
      </Table>

      {showServiceProof && (
        <Modal show={showServiceProof} onHide={() => setShowServiceProof(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Service Payment Proof</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={replacementDetails.serviceFeeProof} alt="Service" style={{width:'100%',height:'auto',maxHeight:'70vh'}} />
          </Modal.Body>
          <Modal.Footer>
            <a href={replacementDetails.serviceFeeProof} download> <Button>Download</Button> </a>
          </Modal.Footer>
        </Modal>
      )}

      {showFinalProof && (
        <Modal show={showFinalProof} onHide={() => setShowFinalProof(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Final Payment Proof</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={replacementDetails.finalPaymentProof} alt="Final" style={{width:'100%',height:'auto',maxHeight:'70vh'}} />
          </Modal.Body>
          <Modal.Footer>
            <a href={replacementDetails.finalPaymentProof} download> <Button>Download</Button> </a>
          </Modal.Footer>
        </Modal>
      )}

      {show && <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{document.documentName}</Modal.Title>
        </Modal.Header>
        <img src={document.document} alt="document" width={'70%'} height={'70%'} style={{ margin: 'auto', padding: '10px' }} />
      </Modal>
      }

    </Container>
  );
};




export default BookingDetails;