import React, { useState, useEffect } from "react";
import axios from "axios";
import { base_url } from "../../constant";

const CouponForm = ({ initialData = null, toggleFormVisibility }) => {
  const initialFormData = {
    couponName: "",
    property: "",
    minimunRentValue: "",
    maximumDiscountValue: "",
    discountType: "",
    discountPercentage: "",
    discountAmount: "",
    couponValidity: "",
    bookDateFrom: "",
    bookDateTo: "",
  };

    const [formData, setFormData] = useState(initialData || initialFormData);
    const [formErrors, setFormErrors] = useState({});
    const [propertyData, setPropertyData] = useState([]);

    const today = new Date().toISOString().split("T")[0];

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        if (!formData.couponName) {
            errors.couponName = "Coupon Name is required";
            isValid = false;
        }
        if (!formData.property) {
            errors.property = "Select Property Name is required";
            isValid = false;
        }
        if (!formData.minimunRentValue) {
            errors.minimunRentValue = "Minimun Rent Value is required";
            isValid = false;
        }
        if (!formData.maximumDiscountValue) {
            errors.maximumDiscountValue = "Maximum Discount Value is required";
            isValid = false;
        }
        if (!formData.discountType) {
            errors.discountType = "Select Discount Type is required";
            isValid = false;
        }
        if (!formData.couponValidity) {
            errors.couponValidity = "Coupon Validity is required";
            isValid = false;
        }
        if (!formData.bookDateFrom) {
            errors.bookDateFrom = "Book Date From is required";
            isValid = false;
        }
        if (!formData.bookDateTo) {
            errors.bookDateTo = "Book Date To is required";
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleInputChange = (value, field) => {
        setFormData({ ...formData, [field]: value });
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
      }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            let payload = {
                couponName: formData.couponName,
                property: formData.property,
                minimunRentValue: formData.minimunRentValue,
                maximumDiscountValue: formData.maximumDiscountValue,
                couponValidity: formData.couponValidity,
                bookDateFrom: formData.bookDateFrom,
                bookDateTo: formData.bookDateTo,
                discountType: formData.discountType
            };
            // Conditionally add discountPercentage or discountAmount based on discountType
            if (formData.discountType === "percentage") {
                payload.discountPercentage = formData.discountPercentage;
            } else if (formData.discountType === "amount") {
                payload.discountAmount = formData.discountAmount;
            }

            const accessToken = localStorage.getItem("x-access-token");
            const refreshToken = localStorage.getItem("x-refresh-token");

            try {
                let response;
                if (initialData?.id) {
                    // Update existing coupon
                    payload.id = initialData?.id;
                    response = await axios.put(`${base_url}/coupon`, payload, {
                        headers: {
                            "x-access-token": accessToken,
                            "x-refresh-token": refreshToken,
                        },
                    });
                    if (response.status === 200){
                      alert("Coupon Updated Successfully");
                      toggleFormVisibility();
                  }
                } else {
                    // Create new coupon
                    response = await axios.post(`${base_url}/coupon`, payload, {
                        headers: {
                            "x-access-token": accessToken,
                            "x-refresh-token": refreshToken,
                        },
                    });
                    if (response.status === 200){
                        alert("Coupon Added Successfully");
                        toggleFormVisibility();
                    }
                }
                handleClear();
            } catch (error) {
                console.error("Error sending form data:", error);
            }
        }
    };

    const handleClear = () => {
        setFormData(initialFormData);
        toggleFormVisibility();
        setFormErrors({});
    };

    const getPropertyData = async () => {
        try {
            const res = await axios.get(`${base_url}/property`);
            setPropertyData(res?.data?.data);
        } catch (error) {
            console.log(error, "Error");
        }
    };

    useEffect(() => {
        getPropertyData();
    }, []);

    return (
        <div>
            <form className="form">
                <div className="form_input">
                    <label htmlFor="name">Coupon Name:</label>
                    <input
                        type="text"
                        id="couponName"
                        name="couponName"
                        className="propertyForm_input"
                        placeholder="Enter Coupon Name"
                        value={formData.couponName}
                        onChange={(e) => handleInputChange(e.target.value, "couponName")}
                        required
                    />
                    {formErrors.couponName && <span style={{ color: "red" }}>{formErrors.couponName}</span>}
                </div>

                <div className="form_input">
                    <label>Property Name:</label>
                    <select className="propertyForm_input" value={formData.property} onChange={(e) => handleInputChange(e.target.value, "property")}>
                        <option value="">Select Property Name</option>
                        {propertyData?.map((item) => (
                            <option key={item?.id} value={item?.id}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form_input">
                    <label htmlFor="minimunRentValue">Minimum Rent Value:</label>
                    <input
                        type="text"
                        id="minimunRentValue"
                        name="minimunRentValue"
                        className="propertyForm_input"
                        placeholder="Enter Minimum Rent Value"
                        value={formData.minimunRentValue}
                        onChange={(e) => handleInputChange(e.target.value, "minimunRentValue")}
                        required
                    />
                    {formErrors.minimunRentValue && <span style={{ color: "red" }}>{formErrors.minimunRentValue}</span>}
                </div>

                <div className="form_input">
                    <label htmlFor="maximumDiscountValue">Maximum Rent Value:</label>
                    <input
                        type="text"
                        id="maximumDiscountValue"
                        name="maximumDiscountValue"
                        className="propertyForm_input"
                        placeholder="Enter Maximum Rent Value"
                        value={formData.maximumDiscountValue}
                        onChange={(e) => handleInputChange(e.target.value, "maximumDiscountValue")}
                        required
                    />
                    {formErrors.maximumDiscountValue && <span style={{ color: "red" }}>{formErrors.maximumDiscountValue}</span>}
                </div>

                <div className="form_input">
                    <label htmlFor="discountType">Discount Type:</label>
                    <select className="propertyForm_input" id="discountType" name="discountType" value={formData.discountType} onChange={(e) => handleInputChange(e.target.value, "discountType")}>
                        <option value="">Select Discount Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="amount">Amount</option>
                    </select>
                </div>

                {formData.discountType === "percentage" && (
                    <div className="form_input">
                        <label htmlFor="discountPercentage">Discount Percentage:</label>
                        <input
                            className="propertyForm_input"
                            type="number"
                            id="discountPercentage"
                            name="discountPercentage"
                            placeholder="Enter Discount Percentage"
                            value={formData.discountPercentage}
                            onChange={(e) => handleInputChange(e.target.value, "discountPercentage")}
                        />
                    </div>
                )}

                {formData.discountType === "amount" && (
                    <div className="form_input">
                        <label htmlFor="discountAmount">Discount Amount:</label>
                        <input
                            className="propertyForm_input"
                            type="number"
                            id="discountAmount"
                            name="discountAmount"
                            placeholder="Enter Discount Amount"
                            value={formData.discountAmount}
                            onChange={(e) => handleInputChange(e.target.value, "discountAmount")}
                        />
                    </div>
                )}

                <div className="form_input">
                    <label>Coupon Validity:</label>
                    <input className="propertyForm_input" type="date" placeholder="Enter Coupon Validity" value={formData.couponValidity} onChange={(e) => handleInputChange(e.target.value, "couponValidity")} min={today} />
                </div>

                <div className="form_input">
                    <label>Book Date From:</label>
                    <input className="propertyForm_input" type="date" placeholder="Enter Book Date From" value={formData.bookDateFrom} onChange={(e) => handleInputChange(e.target.value, "bookDateFrom")} min={today} />
                </div>

                <div className="form_input">
                    <label>Book Date To:</label>
                    <input className="propertyForm_input" type="date" placeholder="Enter Book Date To" value={formData.bookDateTo} onChange={(e) => handleInputChange(e.target.value, "bookDateTo")} min={today} />
                </div>
            </form>

            <div>
                <button className="submit_city" type="submit" onClick={handleSubmit}>
                    Submit
                </button>
                <button className="cancel_city" type="button" onClick={handleClear}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default CouponForm;
