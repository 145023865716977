import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_url } from "../../constant";
import "../../App.css";

const PropertyForm = ({ initialData = null, toggleFormVisibility }) => {
    const [priceInc, setPriceInc] = useState([]);
    const [data, setData] = useState([["", ""]]);
    const [rowCount, setRowCount] = useState(1);
    const [billingItems, setBillingItems] = useState([]);
    const [totalPropertyValue, setTotalPropertyValue] = useState(0);
    const [selectedUniversityIds, setSelectedUniversityIds] = useState([]);

    const [aroundTown, setAroundTown] = useState([]);
    const [cities, setCities] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [otherAmenities, setOtherAmenities] = useState([]);
    const [safteyItems, setSafteyItems] = useState([]);

    const initialFormData = {
        photos: [],
        aboutProperty: "",
        name: "",
        type: "",     
        // buildingType: "",   
        bedroom: 0,
        bath: 0,
        resident: 0,
        size: 0,
        minStay: 0,
        // currency: '',
        price: 0,
        ameinties: [],
        aroundTown: "",
        city: "",
        country: "",
        area: "",
        otherAmenities: "",
        safteyItems: "",
        availableFrom: "",
        locationDescription: "",
        threeTour: "",
        floorPlan: "",
        ttkMessage: "",
        ttkVideo: "",
        universityAssociated: [],
        serviceFee: "",
        longitude: 0,
        latitude: 0,
        //maxmDaysToBookProperty: 0,
        // extraTopicHeading: "",
        // extraTopicDetails: "",
        // repairUpto: "",
        // accountHolderName: "",
        // accountHolderBank: "",
        // accountNumber: "",
    };
    const [formData, setFormData] = useState(initialData || initialFormData);

    useEffect(() => {
        if (initialData) {
            setFormData({
                ...initialData,
                photos: initialData.photos,
                // ameinties: initialData.ameinties,
            });
            setSelectedAmenities(initialData.ameinties || []);
            const associatedUniversityIds = initialData?.universityAssociated?.map((uni) => uni);
            setSelectedUniversityIds(associatedUniversityIds);
            setOtherAmenities(initialData.otherAmenities || []);
            setSafteyItems(initialData.safteyItems || []);
            //setPriceInc(initialData.priceInc);

            if (initialData.priceBreakup) {
                const formattedPriceBreakup = initialData.priceBreakup.filter(item => item.name !== "Service Fee")
                    .map(item => [item.name, item.price.toString()]);
                setData(formattedPriceBreakup);
                setBillingItems(formattedPriceBreakup);
            }
        }
        handleGetCityList();
        handleGetAroundTown();
        handleGetUniversityList();
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "file" ? files : value,
        }));
        if (name === "maxmDaysToBookProperty") {
            setPriceInc([]);
            let price = [];
            for (let i = 0; i < value; i++) {
                price.push(0);
            }
            setPriceInc(price);
        }
    };

    const handleCityChange = (cityId) => {
        const selectedCity = cities.find((city) => city.id === cityId);
        setFormData((prevData) => ({
            ...prevData,
            city: selectedCity.id,
            country: selectedCity.country,
        }));
    };
    const handleAroudTownChange = (townId) => {
        const selectedTown = aroundTown.find((town) => town.id === townId);
        setFormData((prevData) => ({
            ...prevData,
            aroundTown: selectedTown.id,
        }));
    };

    const handleameintiesChange = (e) => {
        const ameinties = e.target.value.split(",").map((amenity) => amenity.trim());
        setFormData((prevData) => ({
            ...prevData,
            ameinties,
        }));
    };

    const handleUniversityAssociatedChange = (e) => {
        const { value, checked } = e.target;
        const universityId = universities.find((uni) => uni.id === value)?.id;

        if (checked) {
            setFormData((prevData) => ({
                ...prevData,
                universityAssociated: [...prevData.universityAssociated, value],
            }));
            setSelectedUniversityIds((prevIds) => [...prevIds, universityId]);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                universityAssociated: prevData.universityAssociated.filter((uni) => uni !== value),
            }));
            setSelectedUniversityIds((prevIds) => prevIds.filter((id) => id !== universityId));
        }
    };
    const formatPriceBreakup = (items, serviceFee) => {
        const breakup = items.map((item) => ({
            name: item[0],
            price: parseFloat(item[1]) || 0,
        }));

        if (parseFloat(serviceFee) > 0) {
            breakup.push({
                name: "Service Fee",
                price: parseFloat(serviceFee),
            });
        }

        return breakup;
    };
    const handleGetCityList = async () => {
        try {
            const res = await axios.get(`${base_url}/city`);
            const cityData = res?.data?.data || [];
            setCities(cityData);
        } catch (error) {
            console.log(error?.response);
        }
    };

    const handleGetAroundTown = async () => {
        try {
            const res = await axios.get(`${base_url}/aroundTown`);
            console.log(res?.data?.data);
            setAroundTown(res?.data?.data);
        } catch (error) {
            console.log(error);
        }
    };
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const ameintiesData = [
        "Wifi", "TV", "Kitchen", "Washing Machine", "Parking access",
        "Air Conditioning", "Heater", "Dedicated workspace",
        "Convertible Sofa Bed", "Single Bed", "Double Bed",
        "Dishes & Silverware"
    ];
    const handleGetUniversityList = async () => {
        try {
            const res = await axios.get(`${base_url}/university`);
            const uniData = res?.data?.data || [];
            setUniversities(uniData);
        } catch (error) {
            console.log(error?.response);
        }
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const paths = files.map((file) => URL.createObjectURL(file));
        setFormData((prevState) => ({
            ...prevState,
            photos: [...prevState.photos, ...files],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

        for (const [key, value] of Object.entries(formData)) {
            if (Array.isArray(value)) {
                for (const item of value) {
                    formDataToSend.append(key, item);
                }
            } else {
                formDataToSend.append(key, value);
            }
        }

        Array.from(formData?.photos).forEach((image, index) => {
            formDataToSend.append("photos", image);
        });

        formDataToSend.set("universityAssociated", JSON.stringify(selectedUniversityIds));
        formDataToSend.set("ameinties", JSON.stringify(selectedAmenities));
        formDataToSend.set("otherAmenities", JSON.stringify(otherAmenities));
        formDataToSend.set("safteyItems", JSON.stringify(safteyItems));
        formDataToSend.set("availableFrom", formData?.availableFrom);
        if (formData.floorPlan && formData.floorPlan.length > 0) {
            formDataToSend.set("floorPlan", formData.floorPlan[0]);
        }
        formDataToSend.set("priceInc", JSON.stringify(priceInc));
        formDataToSend.set("aboutProperty", formData?.aboutProperty);
        formDataToSend.set("priceBreakup", JSON.stringify(formatPriceBreakup(data)));
        formDataToSend.set("totalPrice", totalPropertyValue);

        const accessToken = localStorage.getItem("x-access-token");
        const refreshToken = localStorage.getItem("x-refresh-token");

        try {
            const response = initialData
                ? await axios.patch(`${base_url}/property?id=${initialData.id}`, formDataToSend, {
                    headers: {
                        "x-access-token": accessToken,
                        "x-refresh-token": refreshToken,
                        "Content-Type": "multipart/form-data",
                    },
                })
                : await axios.post(`${base_url}/property`, formDataToSend, {
                    headers: {
                        "x-access-token": accessToken,
                        "x-refresh-token": refreshToken,
                        "Content-Type": "multipart/form-data",
                    },
                });
            if (response.status === 200) {
                toggleFormVisibility();
                setFormData(initialFormData);
            }
        } catch (error) {
            console.error("Error sending form data:", error);
        }
    };

    // const handlePriceChange = async (e, index) => {
    //     const { value } = e.target;
    //     setPriceInc((prevPriceInc) => {
    //         const updatedPriceInc = [...prevPriceInc];
    //         updatedPriceInc[index] = value;
    //         return updatedPriceInc;
    //     });
    // };

    const handleAddRow = () => {
        const newRow = ["", ""];
        setData([...data, newRow]);
    };

    const handleCellChange = (rowIndex, columnIndex, value) => {
        const updatedData = [...data];
        updatedData[rowIndex][columnIndex] = value;
        setData(updatedData);
        setBillingItems(updatedData);
    };

    const handleRemoveRow = (rowIndex) => {
        if (data.length > 1) {
            const updatedData = data.filter((_, index) => index !== rowIndex);
            setData(updatedData);
            setBillingItems(updatedData);
        }
    };

    const calculateBillingTotal = (items, serviceFee) => {
        const itemsTotal = items.reduce((total, item) => total + (parseFloat(item[1]) || 0), 0);
        return itemsTotal + (parseFloat(serviceFee) || 0);
    };

    const handleImageRemove = (index) => {
        const updatedImages = [...formData.photos];
        updatedImages.splice(index, 1);
        setFormData((prevState) => ({
            ...prevState,
            photos: updatedImages,
        }));
    };
    useEffect(() => {
        const billingTotal = calculateBillingTotal(data, formData.serviceFee);
        const propertyPrice = parseFloat(formData.price) || 0;
        setTotalPropertyValue(billingTotal + propertyPrice);
    }, [data, formData.price, formData.serviceFee]);
    
    const otherAmeinties = [
        "Pool", "Hot Tub", "Patio", "BBQ grill", "Dining area",
        "Indoor fireplace", "Gym Access", "Sound System",
        "Smoking area", "Balcony"
    ];
    const safetyOption = [
        "Smoke alarm", "First Aid Kit", "Fire Extinguisher", "Security guard in the premise"
    ];

    return (
        <form onSubmit={handleSubmit} className="form">
            <div className=" form_input">
                <label htmlFor="photos" className="form_label  text-[18px] ">
                    Photos:
                </label>
                <input type="file" name="photos" id="photos" multiple onChange={handleImageUpload} />
            </div>
            <div>
                {formData.photos &&
                    formData.photos.map((image, index) => {
                        return (
                            <div key={index} style={{ display: "inline-block", position: "relative" }}>
                                <img
                                    src={typeof image === "string" ? image : URL.createObjectURL(image)}
                                    alt={`Image ${index}`}
                                    style={{
                                        maxWidth: "140px",
                                        maxHeight: "140px",
                                    }}
                                />
                                <button
                                    type="button"
                                    onClick={() => handleImageRemove(index)}
                                    style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        background: "red",
                                        color: "red",
                                        border: "1px solid red",
                                        backgroundColor: "white",
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                        aspectRatio: "1/1",
                                    }}
                                >
                                    X
                                </button>
                            </div>
                        );
                    })}
            </div>
            <div className=" form_input">
                <label htmlFor="name" className="form_label  text-[18px] ">
                    Name:
                </label>
                <input type="text" name="name" id="name" value={formData?.name} className="propertyForm_input" onChange={handleChange} />
            </div>
            <div className=" form_input">
                <label htmlFor="type" className="form_label  text-[18px] ">
                    Type:
                </label>
                <select name="type" id="type" style={{ border: "1px solid ", borderRadius: "5px", width: "150px" }} value={formData?.type} onChange={handleChange}>
                    <option value="">Select a type</option>
                    <option value="Studio">Studio</option>
                    <option value="1BR">1BR</option>
                    <option value="2BR">2BR</option>
                    <option value="3BR">3BR</option>
                    <option value="4BR">4BR</option>
                </select>
            </div>
            {/* <div className=" form_input">
                <label htmlFor="buildingType" className="form_label  text-[18px] ">
                    Building Type:
                </label>
                <input type="text" name="buildingType" id="buildingType" className="propertyForm_input" value={formData?.buildingType} onChange={handleChange} />
            </div> */}
            {/* <input type="text" name="type" id="type" value={formData?.type} onChange={handleChange} /> */}            
            <div className=" form_input">
                <label htmlFor="aboutProperty" className="form_label  text-[18px] ">
                    About Property:
                </label>
                <textarea name="aboutProperty" className="propertyForm_input " style={{ height: "60px" }} id="aboutProperty" value={formData?.aboutProperty} onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="bedroom" className="form_label  text-[18px] ">
                    Bedrooms:
                </label>
                <input type="number" name="bedroom" className="propertyForm_input" id="bedroom" value={formData?.bedroom} onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="bath" className="form_label  text-[18px] ">
                    Bathrooms:
                </label>
                <input type="number" name="bath" id="bath" className="propertyForm_input" value={formData?.bath} onChange={handleChange} />
            </div>
            <div className=" form_input">
                <label htmlFor="resident" className="form_label  text-[18px] ">
                    Residents:
                </label>
                <input type="number" className="propertyForm_input" name="resident" id="resident" value={formData?.resident} onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="size" className="form_label  text-[18px] ">
                    Size:
                </label>
                <input type="number" name="size" className="propertyForm_input" id="size" value={formData?.size} onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="minStay" className="form_label  text-[18px] ">
                    Minimum Stay (In Days):
                </label>
                <input type="number" name="minStay" className="propertyForm_input" id="minStay" value={formData?.minStay} onChange={handleChange} />
            </div>
            <div className=" form_input">
                <label htmlFor="price" className="form_label  text-[18px] ">
                    Price:
                </label>
                <input type="number" name="price" className="propertyForm_input" id="price" value={formData?.price} onChange={handleChange} />
            </div>

            {/* <div className=" form_input">
                <label htmlFor="ameinties" className="form_label  text-[18px] ">
                    Amenities (comma-separated):
                </label>
                <input type="text" name="ameinties" id="ameinties" value={formData?.ameinties && formData?.ameinties.join(",")} onChange={handleameintiesChange} />
            </div> */}
             <div className="form_input">
                <label className="form_label text-[18px]">Amenities:</label>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
                    {ameintiesData.map((amenity, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                            <input
                                type="checkbox"
                                id={`amenity-${index}`}
                                name="amenities"
                                value={amenity}
                                checked={selectedAmenities.includes(amenity)}
                                onChange={(e) => {
                                    const { checked, value } = e.target;
                                    setSelectedAmenities(prev =>
                                        checked
                                            ? [...prev, value]
                                            : prev.filter(item => item !== value)
                                    );
                                }}
                                style={{ marginRight: "8px" }}
                            />
                            <label htmlFor={`amenity-${index}`}>{amenity}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div className=" form_input">
                <label htmlFor="billing" className="form_label  text-[18px] ">
                    Billing:
                </label>
                <div>
                    <div className="add-buttons">
                        <button type="button" className="add-button" onClick={handleAddRow} aria-label="Add Row">
                            Add Row
                        </button>
                    </div>

                    <div className="flex flex-col w-full overflow-x-auto overflow-hidden">
                        {data?.map((row, rowIndex) => (
                            <div key={`row-${rowIndex}`} className="flex items-center">
                                {row?.map((cell, columnIndex) => (
                                    <div key={`cell-${rowIndex}-${columnIndex}`} className="cell-container">
                                        {/* <input
                                            type="text"
                                            value={cell}
                                            onChange={(e) => handleCellChange(rowIndex, columnIndex, e.target.value)}
                                            className="addcountrynameinput"
                                            placeholder={columnIndex !== 1 ? "Enter Utility" : "Enter Price"}
                                        /> */}
                                        {columnIndex === 0 ? (
                                            <select
                                                value={cell}
                                                onChange={(e) => handleCellChange(rowIndex, columnIndex, e.target.value)}
                                                className="addcountrynameinput"
                                            >
                                                <option value="">Select Utility</option>
                                                {ameintiesData.map((ele, index) => (
                                                    <option key={index} value={ele}>
                                                        {ele}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <input
                                                type="text"
                                                value={cell}
                                                onChange={(e) => handleCellChange(rowIndex, columnIndex, e.target.value)}
                                                className="addcountrynameinput"
                                                placeholder="Enter Price"
                                            />
                                        )}
                                    </div>
                                ))}
                                {data.length > 1 && (
                                    <button type="button" onClick={() => handleRemoveRow(rowIndex)} className="remove-button">
                                        Remove Row
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="form_input">
                <label className="form_label text-[18px]">Other Amenities:</label>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
                    {otherAmeinties.map((amenity, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                            <input
                                type="checkbox"
                                id={`otherAmenity-${index}`}
                                name="otherAmenities"
                                value={amenity}
                                checked={otherAmenities.includes(amenity)}
                                onChange={(e) => {
                                    const { checked, value } = e.target;
                                    setOtherAmenities(prev =>
                                        checked
                                            ? [...prev, value]
                                            : prev.filter(item => item !== value)
                                    );
                                }}
                                style={{ marginRight: "8px" }}
                            />
                            <label htmlFor={`otherAmenity-${index}`}>{amenity}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div className="form_input">
                <label className="form_label text-[18px]">Safety Items:</label>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
                    {safetyOption.map((item, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center" }}>
                            <input
                                type="checkbox"
                                id={`safetyItems-${index}`}
                                name="safteyItems"
                                value={item}
                                checked={safteyItems.includes(item)}
                                onChange={(e) => {
                                    const { checked, value } = e.target;
                                    setSafteyItems(prev =>
                                        checked ? [...prev, value] : prev.filter(i => i !== value)
                                    );
                                }}
                                style={{ marginRight: "8px" }}
                            />
                            <label htmlFor={`safteyItems-${index}`}>{item}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div className=" form_input">
                <label htmlFor="serviceFee" className="form_label  text-[18px] ">
                    Verification Fee:
                </label>
                <input type="text" name="serviceFee" id="serviceFee" value={formData?.serviceFee} className="propertyForm_input" onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="totalPropertyValue" className="form_label  text-[18px] ">
                    Total Property Value:
                </label>
                <input type="text" name="totalPropertyValue" id="totalPropertyValue" value={totalPropertyValue} className="propertyForm_input" readOnly />
            </div>

            <div className=" form_input">
                <label htmlFor="availableFrom" className="form_label  text-[18px] ">
                    Available From:
                </label>
                <input type="date" name="availableFrom" id="availableFrom" onChange={handleChange} value={formData?.availableFrom} className="propertyForm_input" />
            </div>

            <div className=" form_input">
                <label htmlFor="city" className="form_label  text-[18px] ">
                    Around Town:
                </label>
                <select name="city" id="city" style={{ border: "1px solid ", borderRadius: "5px", width: "150px" }} value={formData?.aroundTown} onChange={(e) => handleAroudTownChange(e.target.value)}>
                    <option value="">Select a city</option>
                    {aroundTown.map((town) => (
                        <option key={town.id} value={town.id}>
                            {town.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className=" form_input">
                <label htmlFor="city" className="form_label  text-[18px] ">
                    City:
                </label>
                <select name="city" id="city" style={{ border: "1px solid ", borderRadius: "5px", width: "150px" }} value={formData?.city} onChange={(e) => handleCityChange(e.target.value)}>
                    <option value="">Select a city</option>
                    {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                            {city.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className=" form_input">
                <label htmlFor="country" className="form_label  text-[18px] ">
                    Country:
                </label>
                <input type="text" name="country" id="country" className="propertyForm_input" value={formData?.country} readOnly />
            </div>
            <div className=" form_input">
                <label htmlFor="area" className="form_label  text-[18px] ">
                    Area:
                </label>
                <input type="text" name="area" id="area" value={formData?.area} onChange={handleChange} className="propertyForm_input" />
            </div>
            <div className=" form_input">
                <label htmlFor="locationDescription" className="form_label  text-[18px] ">
                    Location Description:
                </label>
                <textarea name="locationDescription" id="locationDescription" value={formData?.locationDescription} className="propertyForm_input" style={{ height: "60px" }} onChange={handleChange} />
            </div>
            <div className=" form_input">
                <label htmlFor="threeTour" className="form_label  text-[18px] ">
                    3D Tour:
                </label>
                <input name="threeTour" id="threeTour" value={formData?.threeTour} onChange={handleChange} className="propertyForm_input" />
            </div>
            <div className=" form_input">
                <label htmlFor="floorPlan" className="form_label  text-[18px] ">
                    Floor Plan:
                </label>
                <input type="file" name="floorPlan" id="floorPlan" onChange={handleChange} />
            </div>
            <div className=" form_input">
                <label htmlFor="ttkVideo" className="form_label  text-[18px] ">
                    TTK Video:
                </label>
                <input name="ttkVideo" id="ttkVideo" value={formData?.ttkVideo} onChange={handleChange} className="propertyForm_input" />
            </div>
            <div className=" form_input">
                <label htmlFor="ttkMessage" className="form_label  text-[18px] ">
                    TTK Message:
                </label>
                <input type="text" name="ttkMessage" id="ttkMessage" value={formData?.ttkMessage} className="propertyForm_input" onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="universityAssociated" className="form_label  text-[18px] ">
                    Associated Universities:
                </label>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "10px",
                    }}
                >
                    {universities.map((university) => (
                        <div key={university.id} style={{ display: "flex", alignItems: "center" }}>
                            <input
                                type="checkbox"
                                id={`university-${university.id}`}
                                name="universityAssociated"
                                value={university.id}
                                checked={formData?.universityAssociated.includes(university.id)}
                                onChange={handleUniversityAssociatedChange}
                                style={{ marginRight: "8px" }}
                            />
                            <label htmlFor={`university-${university.id}`}>{university.name}</label>
                        </div>
                    ))}
                </div>
            </div>

            <div className=" form_input">
                <label htmlFor="longitude" className="form_label  text-[18px] ">
                    Longitude:
                </label>
                <input type="text" name="longitude" id="longitude" value={formData?.longitude} onChange={handleChange} />
            </div>
            <div className=" form_input">
                <label htmlFor="latitude" className="form_label  text-[18px] ">
                    Latitude:
                </label>
                <input type="text" name="latitude" className="propertyForm_input" id="latitude" value={formData?.latitude} onChange={handleChange} />
            </div>

            {/* <div className=" form_input">
                <label htmlFor="extraTopicHeading" className="form_label  text-[18px] ">
                    Extra topic heading:
                </label>
                <input type="text" name="extraTopicHeading" id="extraTopicHeading" value={formData?.extraTopicHeading} onChange={handleChange} className="propertyForm_input" />
            </div>
            <div className=" form_input">
                <label htmlFor="extraTopicDetails" className="form_label  text-[18px] ">
                    Extra topic details:
                </label>
                <input type="text" name="extraTopicDetails" id="extraTopicDetails" className="propertyForm_input" value={formData?.extraTopicDetails} onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="repairUpto" className="form_label  text-[18px] ">
                    Repair upto:
                </label>
                <input type="text" name="repairUpto" id="repairUpto" className="propertyForm_input" value={formData?.repairUpto} onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="accountHolderName" className="form_label  text-[18px] ">
                    Account holder name:
                </label>
                <input type="text" name="accountHolderName" id="accountHolderName" value={formData?.accountHolderName} className="propertyForm_input" onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="accountHolderBank" className="form_label  text-[18px] ">
                    Account holder bank:
                </label>
                <input type="text" name="accountHolderBank" className="propertyForm_input" id="accountHolderBank" value={formData?.accountHolderBank} onChange={handleChange} />
            </div>

            <div className=" form_input">
                <label htmlFor="accountNumber" className="form_label  text-[18px] ">
                    Account Number:
                </label>
                <input type="text" name="accountNumber" className="propertyForm_input" id="accountNumber" value={formData?.accountNumber} onChange={handleChange} />
            </div> */}

            {/* <div className=" form_input">
                <label htmlFor="maxmDaysToBookProperty" className="form_label  text-[18px] ">
                    Available to Book (No. of Days):
                </label>
                <input type="number" name="maxmDaysToBookProperty" id="maxmDaysToBookProperty" className="propertyForm_input" value={formData?.maxmDaysToBookProperty} onChange={handleChange} />
            </div>

            <p>Percent of Increase Per Day:</p>

            {[...Array(parseInt(formData.maxmDaysToBookProperty || 0)).keys()].map((i) => (
                <div key={i}>
                    <label htmlFor={`priceIncrease_${i}`}>Increase In Price After {i + 1} Days:</label>
                    <input
                        type="number"
                        name={`priceIncrease_${i}`}
                        id={`priceIncrease_${i}`}
                        value={priceInc[i]}
                        onChange={(e) => {
                            handlePriceChange(e, i);
                        }}
                    />
                </div>
            ))} */}

            <div>
                <button type="submit" className="submit_city">
                    Submit
                </button>
                <button className="cancel_city" type="button" onClick={toggleFormVisibility}>
                    Cancel
                </button>
            </div>
        </form>
    );
};

export default PropertyForm;
