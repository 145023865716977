import React, { useState, useEffect } from "react";
import axios from "axios";
import { base_url } from "../../constant";

const ManualBookingForm = ({ initialData = null, toggleFormVisibility }) => {
  const initialFormData = {
    property: "",
    bookFrom: "",
    bookTo: "",
  };

  const [formData, setFormData] = useState(initialData || initialFormData);

    const [formErrors, setFormErrors] = useState({});
    const [propertyData, setPropertyData] = useState([]);

    const today = new Date().toISOString().split("T")[0];

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        if (!formData.property) {
            errors.property = "Select Property Name is required";
            isValid = false;
        }
        if (!formData.bookFrom) {
            errors.bookFrom = "Book Date From is required";
            isValid = false;
        }
        if (!formData.bookTo) {
            errors.bookTo = "Book Date To is required";
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleInputChange = (value, field) => {
        setFormData({ ...formData, [field]: value });
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [field]: "",
      }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            let payload = {
                propertyId: formData.property,
                bookFrom: formData.bookFrom,
                bookTo: formData.bookTo,
            };
              
            const accessToken = localStorage.getItem("x-access-token");
            const refreshToken = localStorage.getItem("x-refresh-token");

            try {
                let response;
                if (initialData?.id) {
                    // Update existing coupon
                    payload.id = initialData?.id;
                    response = await axios.put(`${base_url}/booking/manual-booking`, payload, {
                        headers: {
                            "x-access-token": accessToken,
                            "x-refresh-token": refreshToken,
                        },
                    });
                    if (response.status === 200){
                      alert("Manual Booking Updated Successfully");
                      toggleFormVisibility();
                  }
                } else {
                    // Create new coupon
                    response = await axios.post(`${base_url}/booking/manual-booking`, payload, {
                        headers: {
                            "x-access-token": accessToken,
                            "x-refresh-token": refreshToken,
                        },
                    });
                    if (response.status === 200){
                        alert("Manual Booking Added Successfully");
                        toggleFormVisibility();
                    }
                }
                handleClear();
            } catch (error) {
                console.error("Error sending form data:", error);
            }
        }
    };

    const handleClear = () => {
        setFormData(initialFormData);
        toggleFormVisibility();
        setFormErrors({});
    };

    const getPropertyData = async () => {
        try {
          const res = await axios.get(`${base_url}/property`);
          setPropertyData(res?.data?.data);
    
          // Set initial property data if editing
          if (initialData) {
            const matchedProperty = res?.data?.data.find(
              (property) => property?.id === initialData?.propertyId
            );
            if (matchedProperty) {
              setFormData({
                ...initialFormData,
                property: matchedProperty?.id,
                bookFrom: initialData?.bookFrom,
                bookTo: initialData?.bookTo,
              });
            }
          }
        } catch (error) {
          console.log(error, "Error");
        }
      };

    useEffect(() => {
        getPropertyData();
    }, []);

    return (
        <div>
            <form className="form">
                <div className="form_input">
                    <label>Property Name:</label>
                    <select className="propertyForm_input" value={formData?.property} onChange={(e) => handleInputChange(e.target.value, "property")}>
                        <option value="">Select Property Name</option>
                        {propertyData?.map((item) => (
                            <option key={item?.id} value={item?.id}>
                                {item?.name}
                            </option>
                        ))}
                    </select>
                    {formErrors.property && <span style={{ color: "red" }}>{formErrors.property}</span>}
                </div>

                <div className="form_input">
                    <label>Book Date From:</label>
                    <input className="propertyForm_input" type="date" placeholder="Enter Book Date From" value={formData.bookFrom} onChange={(e) => handleInputChange(e.target.value, "bookFrom")} min={today} />
                    {formErrors.bookFrom && <span style={{ color: "red" }}>{formErrors.bookFrom}</span>}
                </div>

                <div className="form_input">
                    <label>Book Date To:</label>
                    <input className="propertyForm_input" type="date" placeholder="Enter Book Date To" value={formData.bookTo} onChange={(e) => handleInputChange(e.target.value, "bookTo")} min={today} />
                    {formErrors.bookTo && <span style={{ color: "red" }}>{formErrors.bookTo}</span>}
                </div>
            </form>

            <div>
                <button className="submit_city" type="submit" onClick={handleSubmit}>
                    Submit
                </button>
                <button className="cancel_city" type="button" onClick={handleClear}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default ManualBookingForm;
