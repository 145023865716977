import React, { useState, useEffect } from "react";

import { base_url } from "../../constant";
import axios from "axios";

const FaqForm = ({ initialData = null ,toggleFormVisibility}) => {
  const initialFormData = {
    question: "",
    answer: "",
  };


  const [formData, setFormData] = useState(initialData || initialFormData);  
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
      });
    }
  }, [initialData]);

  console.log(formData,"form");

  const validateForm = () => {
    let errors = {}
    let isValid = true;
    if (!formData.question) {
      errors.question = "Question is required";
      isValid = false;
    }

    if (!formData.answer) {
      errors.answer = "Answer is required";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleChange = async (e, index) => {
    const { name, value } = e.target;
    
      setFormData({
        ...formData,
        [name]: value,
      });

  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
    }
    const accessToken = localStorage.getItem("x-access-token");
    const refreshToken = localStorage.getItem("x-refresh-token");

    let payload={}
    payload.question = formData?.question
    payload.answer = formData?.answer

    if(initialData){ 
      payload.id =initialData.id;
    }
    try {
      const response = initialData
        ? await axios.put(`${base_url}/faq`, payload, {
          headers: {
            "x-access-token": accessToken,
            "x-refresh-token": refreshToken,
          },
        })
        : await axios.post(`${base_url}/faq`, payload, {
          headers: {
            "x-access-token": accessToken,
            "x-refresh-token": refreshToken,
           
          },
        });

      console.log("Form data sent successfully:", response.data);
     
      handleClear()
    } catch (error) {
      console.error("Error sending form data:", error);
    }

  
  };

  const handleClear = () => {
    setFormData(initialFormData);
    toggleFormVisibility()
    setFormErrors({});
  };



  return (
    <div>
      <form  className="form">
  
        <div className="form_input">
          <label htmlFor="name">Question:</label>
          <input
            type="text"
            id="question"
            name="question"
            className="propertyForm_input"
            placeholder="Add question"
            value={formData.question}
            onChange={(e) => handleChange(e)}
            required
          />
          {formErrors.question && <span style={{ color: "red" }}>{formErrors.question}</span>}
        </div>
        <div className="form_input">
          <label htmlFor="country" style={{ marginTop: "10px" }}>
            Answer:
          </label>
          <textarea
            id="answer"
            name="answer"
            
            value={formData.answer}
            onChange={(e) => handleChange(e)}
            className="propertyForm_input"
            style={{height:'70px'}}
            required
          />
          {formErrors.answer && <span style={{ color: "red" }}>{formErrors.answer}</span>}
          
        </div>
      </form>
      <div>
        <button className="submit_city" type="submit" onClick={handleSubmit}>
          Submit
        </button>
        <button className="cancel_city" type="button" onClick={handleClear}>
          Cancel
        </button>
      </div>
    </div>
  
  );
};

export default FaqForm;
