import React, { useEffect, useState } from 'react';
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import axios from 'axios';
import { base_url } from '../../constant';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const OngoingBookingList = () => {


  // Mapping function to render booking cards
  const [data, setData] = useState([])
  const [filteredData,setFilteredData] = useState([]);
  const [active,setActive] = useState('single')
  const navigate = useNavigate();
  const handleBookingFilter = (filter) => {
      setActive(filter);

  }
  const getBookingList = async () => {
    try {
      const accessToken = localStorage.getItem('x-access-token');
      const refreshToken = localStorage.getItem('x-refresh-token');
      const res = await axios.get(`${base_url}/booking/adminOngoingBookingList`, {
        headers: {
          'x-access-token': accessToken,
          'x-refresh-token': refreshToken,
        }
      });
      await res?.data?.data.sort((a, b) => (a.createdAt < b.createdAt))
      setData(res?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSingleBookClick = (id) => {
    console.log(id, "Idd")
    navigate(`/bookingDetails?id=${id}`)
  }

  useEffect(() => {
    getBookingList();
  }, [])


  return (
    <div className='mainContainer'>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content" style={{width:'80%'}}> 
        <div className="container">
          <FirstNavbar />
          <div >
            <button className={`btn-booking ${active === 'single' && 'active'}`} onClick={() => handleBookingFilter('single')}>Single Booking</button>
            <button className={`btn-booking ${active === 'group' && 'active'}`} onClick={() => handleBookingFilter('group')}>Group Booking</button>
          </div>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Booking ID</th>
                <th>Property Name</th>
                <th>Area</th>
                <th>City</th>
                <th>Country</th>
                <th>Status</th>
                <th>Monthly Rent</th>
                <th>Service Fee</th>
                <th>Move-in Date</th>
                <th>Move-out Date</th> 
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {data.filter(item => item.type === active).map((item) => (
                <tr key={item.id} onClick={() => handleSingleBookClick(item.id)}>
                  <td style={{width:'100px', textOverflow:'ellipsis', lineClamp:'1'}}>{item.bookingUniqueId}</td>
                  <td>{item.name}</td>
                  <td>{item.area}</td>
                  <td>{item.city_name}</td>
                  <td>{item.country}</td>
                  <td>{item.status}</td>
                  <td>{parseInt(item.monthlyRent)}</td>
                  <td>{item.serviceFee}</td>
                  <td>{item.moveinDate}</td>
                  <td>{item.moveOutData}</td>              
                  <td>{item.createdAt.slice(0, 10).split('-').reverse().join('/')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default OngoingBookingList;
