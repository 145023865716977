import React, { useEffect, useState } from 'react';
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import axios from 'axios';
import { base_url } from '../../constant';
import { Table } from 'react-bootstrap';

const Subscribe = () => {

  const [data, setData] = useState([])
 
  const getSubscribeList = async () => {
    try {
      const accessToken = localStorage.getItem('x-access-token');
      const refreshToken = localStorage.getItem('x-refresh-token');
      const res = await axios.get(`${base_url}/enquiry/news-letter`, {
        headers: {
          'x-access-token': accessToken,
          'x-refresh-token': refreshToken,
        }
      });
      await res?.data?.data?.sort((a, b) => (a.createdAt < b.createdAt))
      setData(res?.data?.data)
      console.log("res", res.data.data);
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getSubscribeList();
  }, [])


  return (
    <div className='mainContainer'>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content" style={{width:'77%'}}> 
        <div className="container">
          <FirstNavbar />
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Subscribe Id</th>
                <th>Subscribe Name</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td style={{width: '50%'}}>{item.id}</td>
                  <td>{item.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
