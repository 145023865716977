import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { base_url } from '../constant';
import SideBar from './dashboard/SideBar';
import styled from "styled-components";
import FirstNavbar from './dashboard/FirstNavbar';

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: end;
`;

const SidebarContainer = styled.div`
  width: 300px;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Enquiry = () => {
    const [enquiries, setEnquiries] = useState([]);

    useEffect(() => {
        fetchEnquiries();
    }, []);

    const fetchEnquiries = async () => {
        try {
            const response = await axios.get(`${base_url}/enquiry`);
            setEnquiries(response?.data?.data);
        } catch (error) {
            console.error('Error fetching enquiries:', error);
        }
    };

    const updateStatus = async (id, status) => {
        try {
            await axios.get(`${base_url}/enquiry/update/?id=${id}&&status=${ status }`); // Adjust the API endpoint accordingly
            // Update local state or refetch enquiries
            fetchEnquiries();
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    return (
        <Container>
            <SidebarContainer>
                <SideBar />
            </SidebarContainer>
            <Content>
                <FirstNavbar/>
                <h1>Enquiries</h1>
                <Table>
                    <thead>
                        <tr>
                            <Th>ID</Th>
                            <Th>Email</Th>
                            <Th>Phone</Th>
                            <Th>Enquiry</Th>
                            <Th>Status</Th>
                            <Th>Update Status</Th>
                        </tr>
                    </thead>
                    <tbody>
                        {enquiries.map(enquiry => (
                            <tr key={enquiry.id}>
                                <Td>{enquiry.id}</Td>
                                <Td>{enquiry.email}</Td>
                                <Td>{enquiry.phone}</Td>
                                <Td>{enquiry.enquiry}</Td>
                                <Td>{enquiry.status}</Td>
                                <Td>
                                    <select value={enquiry?.status} onChange={e => updateStatus(enquiry.id, e.target.value)}>
                                        <option value="new">New</option>
                                        <option value="recontact">Recontact</option>
                                        <option value="enquired">Enquired</option>
                                    </select>
                                </Td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Content>
        </Container>
    );
};

export default Enquiry;