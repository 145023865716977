import React, { useState } from "react";
import axios from "axios";
import { base_url } from "../../constant";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const BlogForm = ({ initialData = null, toggleFormVisibility }) => {
  const initialFormData = {
    heading: "",
    category: "",
    description: "",
    bannerImage: "", // Separate field for banner image
    image: "", // Separate field for blog image
  };

  const [formData, setFormData] = useState(initialData || initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [blogImage, setBlogImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.heading) {
      errors.heading = "Heading is required";
      isValid = false;
    }

    if (!formData.category) {
      errors.category = "Category is required";
      isValid = false;
    }

    // Validate description based on whether it's empty or not
    if (!formData.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }

    if (!bannerImage && !initialData?.bannerImage) {
      errors.bannerImage = "Banner Image is required";
      isValid = false;
    }

    if (!blogImage && !initialData?.image) {
      errors.blogImage = "Blog Image is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleInputChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleBannerImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBannerImage(file);
    }
  };

  const handleBlogImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBlogImage(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append("heading", formData.heading);
      formDataToSend.append("category", formData.category);
      formDataToSend.append("description", formData.description);
      if (bannerImage) {
        formDataToSend.append("bannerImage", bannerImage);
      } else {
        formDataToSend.append("bannerImage", formData.bannerImage);
      }

      // Append only if blogImage has changed
      if (blogImage) {
        formDataToSend.append("image", blogImage);
      } else {
        formDataToSend.append("image", formData.image);
      }
  
      const accessToken = localStorage.getItem("x-access-token");
      const refreshToken = localStorage.getItem("x-refresh-token");
  
      try {
        let response;
        if (initialData?.id) {
          // Update existing blog post using PATCH
          formDataToSend.append("id", initialData?.id); // Include id in FormData if required by backend
  
          response = await axios.patch(
            `${base_url}/blogs`,
            formDataToSend,
            {
              headers: {
                "x-access-token": accessToken,
                "x-refresh-token": refreshToken,
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } else {
          // Create new blog post using POST
          response = await axios.post(`${base_url}/blogs`, formDataToSend, {
            headers: {
              "x-access-token": accessToken,
              "x-refresh-token": refreshToken,
              "Content-Type": "multipart/form-data",
            },
          });
        }
  
        console.log("Form data sent successfully:", response.data);
        handleClear();
      } catch (error) {
        console.error("Error sending form data:", error);
      }
    }
  };
  
  
  const handleClear = () => {
    setFormData(initialFormData);
    toggleFormVisibility();
    setFormErrors({});
    setBlogImage(null);
    setBannerImage(null);
  };

  return (
    <div>
      <form className="form">
        <div className="form_input">
          <label htmlFor="name">Heading:</label>
          <input
            type="text"
            id="name"
            name="heading"
            className="propertyForm_input"
            placeholder="Enter heading"
            value={formData.heading}
            onChange={(e) => handleInputChange(e.target.value, "heading")}
            required
          />
          {formErrors.heading && (
            <span style={{ color: "red" }}>{formErrors.heading}</span>
          )}
        </div>

        <div className="form_input">
          <label htmlFor="category">Category:</label>
          <input
            type="text"
            id="category"
            name="category"
            className="propertyForm_input"
            placeholder="Enter category"
            value={formData.category}
            onChange={(e) => handleInputChange(e.target.value, "category")}
            required
          />
          {formErrors.category && (
            <span style={{ color: "red" }}>{formErrors.category}</span>
          )}
        </div>

        <div className="form_input">
          <label>Description:</label>
          <ReactQuill
            theme="snow"
            value={formData.description}
            onChange={(value) => handleInputChange(value, "description")}
          />
          {formErrors.description && (
            <span style={{ color: "red" }}>{formErrors.description}</span>
          )}
        </div>

        <div className="form_input">
          <label htmlFor="bannerImage">Banner Image:</label>
          <input
            type="file"
            id="bannerImage"
            name="bannerImage"
            accept="image/*"
            onChange={handleBannerImageUpload}
          />
          
          {formErrors.bannerImage && (
            <span style={{ color: "red" }}>{formErrors.bannerImage}</span>
          )}
        </div>

        {bannerImage && (  // Update this part to formData.image
            <img
              src={URL.createObjectURL(bannerImage)}  // Assuming image is correctly set in formData
              alt="BlogImage"
              style={{
                maxWidth: "140px",
                maxHeight: "140px",
              }}
            />
          )}

        <div className="form_input">
          <label htmlFor="blogImage">Blog Image:</label>
          <input
            type="file"
            id="blogImage"
            name="blogImage"
            accept="image/*"
            onChange={handleBlogImageUpload}
          />
          {formErrors.blogImage && (
            <span style={{ color: "red" }}>{formErrors.blogImage}</span>
          )}

        {blogImage && (  // Update this part to formData.image
            <img
              src={URL.createObjectURL(blogImage)} // Assuming image is correctly set in formData
              alt="BlogImage"
              style={{
                maxWidth: "140px",
                maxHeight: "140px",
              }}
            />
          )}
        </div>
      </form>

      <div>
        <button className="submit_city" type="submit" onClick={handleSubmit}>
          Submit
        </button>
        <button className="cancel_city" type="button" onClick={handleClear}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default BlogForm;
