import axios from "axios";
import React, { useEffect, useState } from "react";
import { base_url } from "../../constant";

const UniversityForm = ({ university = null ,toggleFormVisibility}) => {
  const initialFormData = {
    images: null,
    name: "",
    country: "",
    city: "",
  };
  const [univerSityformData, setUniverSityFormData] = useState(
    university || initialFormData
  );
  const [cityData, setCityData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "city") {
      const selectedCity = cityData.find((city) => city.name === value);
      setUniverSityFormData({
        ...univerSityformData,
        [name]: value,
        country: selectedCity?.country || "", // Update country based on selected city
      });
    } else {
      setUniverSityFormData({
        ...univerSityformData,
        [name]: value,
      });
    }
  };

  const handleSingleImageUpload = (e) => {
    const file = e.target.files[0];
    setUniverSityFormData({
      ...univerSityformData,
      images: file,
    });
  };

  const handleGetCityList = async () => {
    try {
      const res = await axios.get(`${base_url}/city`);
      const cityData = res?.data?.data?.map((item) => ({
        id: item.name,
        name: item.name,
        country: item.country,
      }));
      setCityData(cityData);
    } catch (error) {
      console.log(error?.response);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem("x-access-token");
    const refreshToken = localStorage.getItem("x-refresh-token");

    const formDataToSend = new FormData();

    const changedFields = Object.keys(univerSityformData).filter(
      (key) =>
        univerSityformData[key] !== (university ? university[key] : initialFormData[key])
    );
  
    changedFields.forEach((field) => {
      if (field === "images") {
        formDataToSend.append("logo", univerSityformData[field]);
      } else {
        formDataToSend.append(field, univerSityformData[field]);
      }
    });

    try {
      const response = university
        ? await axios.patch(
            `${base_url}/university?id=${university.id}`,
            formDataToSend,
            {
              headers: {
                "x-access-token": accessToken,
                "x-refresh-token": refreshToken,
                "Content-Type": "multipart/form-data",
              },
            }
          )
        : await axios.post(`${base_url}/university`, formDataToSend, {
            headers: {
              "x-access-token": accessToken,
              "x-refresh-token": refreshToken,
              "Content-Type": "multipart/form-data",
            },
          });

      console.log("Form data sent successfully:", response.data);
      
      handleClear();
    } catch (error) {
      console.log(error);
    }
    console.log(univerSityformData);
  };

  const handleClear = () => {
    setUniverSityFormData(initialFormData);
    toggleFormVisibility()
  };

  useEffect(() => {
    handleGetCityList();
    if (university) {
      setUniverSityFormData({
        ...university,
        images: null, // Reset the images field
      });
    }
  }, [university]);

  return (
    <form onSubmit={handleSubmit} className="form" >

      <div style={{ marginBottom: '10px' }} className="form_input">
        <label htmlFor="images">Logo:</label>
        <input
          type="file"
          id="images"
          name="images"
          accept="image/*"
          onChange={handleSingleImageUpload}
          required
        />
      </div>
  
      {univerSityformData.images && (
        <div style={{ marginBottom: '10px' }}>
          <img
            src={
              typeof univerSityformData.images === "string"
                ? univerSityformData.images
                : URL.createObjectURL(univerSityformData.images)
            }
            alt="SelectedImage"
            style={{
              width: "140px",
              height: "140px",
              aspectRatio: "1 / 1", // Ensure the image is square
              clipPath: "circle(50%)", // Make the image round
              objectFit: "cover", // Ensures the image covers the entire round shape
              margin: "5px",
            }}
          />
        </div>
      )}
  
      <div style={{ marginBottom: '10px' }} className="form_input">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          className="propertyForm_input"
          placeholder="enter your name"
          name="name"
          value={univerSityformData.name}
          onChange={handleChange}
          required
        />
      </div>
  
      <div style={{ marginBottom: '10px' }} className="form_input">
        <label htmlFor="city">City:</label>
        <select
          id="city"
          name="city"
          className="propertyForm_input"
          value={univerSityformData.city}
          style={{ width: "150px", height: "40px" }}
          onChange={handleChange}
          required
        >
          <option value="">Select a city</option>
          {cityData.map((city) => (
            <option key={city.id} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>
      </div>
  
      <div style={{ marginBottom: '10px' }} className="form_input">
        <label htmlFor="country">Country:</label>
        <input
          type="text"
          id="country"
          className="propertyForm_input"
          name="country"
          placeholder="enter yout country"
          value={univerSityformData.country}
          onChange={handleChange}
          required
        />
      </div>
  
      <div  >
        <button className="submit_city" type="submit">
          {university ? "Update" : "Submit"}
        </button>
        <button
          className="cancel_city"
          type="button"
          onClick={handleClear}
        >
          Cancel
        </button>
      </div>

  </form>
  
  );
};

export default UniversityForm;