import React, { useState } from "react";
import axios from "axios";
import { base_url } from "../../constant";

const BankForm = ({ initialData = null, toggleFormVisibility }) => {
  const initialFormData = {
    bankName: "",
    beneficiaryName: "",
    accountNumber: "",
    IFSC: "",
  };

  const [formData, setFormData] = useState(initialData || initialFormData);
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.bankName) {
      errors.bankName = "Bank Name is required";
      isValid = false;
    }

    if (!formData.beneficiaryName) {
        errors.beneficiaryName = "Benficiary Name is required";
        isValid = false;
    }

    if (!formData.accountNumber) {
        errors.accountNumber = "Account Number is required";
        isValid = false;
    }

    if (!formData.IFSC) {
      errors.IFSC = "IFSC Code is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleInputChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const requestData = {
        bankName: formData.bankName,
        beneficiaryName: formData.beneficiaryName,
        accountNumber: formData.accountNumber,
        IFSC: formData.IFSC,
      };
  console.log(requestData);
      const accessToken = localStorage.getItem("x-access-token");
      const refreshToken = localStorage.getItem("x-refresh-token");
  
      try {
        let response;
        if (initialData?.id) {
          // Editing existing bank data (PATCH request)
          requestData.id = initialData.id; // Include ID in request data
          response = await axios.patch(
            `${base_url}/admin-bank`,
            requestData,
            {
              headers: {
                "x-access-token": accessToken,
                "x-refresh-token": refreshToken,
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } else {
          // Creating new bank data (POST request)
          response = await axios.post(`${base_url}/admin-bank`, requestData, {
            headers: {
              "x-access-token": accessToken,
              "x-refresh-token": refreshToken,
            },
          });
        }
  
        console.log("Form data sent successfully:", response.data);
        handleClear();
      } catch (error) {
        console.error("Error sending form data:", error);
      }
    }
  };
  
  

  const handleClear = () => {
    setFormData(initialFormData);
    toggleFormVisibility();
    setFormErrors({});
  };

  return (
    <div>
      <form className="form">
        
        <div className="form_input">
          <label htmlFor="beneficiaryName">Lessor Name:</label>
          <input
            type="text"
            id="beneficiaryName"
            name="heading"
            className="propertyForm_input"
            placeholder="Enter Beneficiary Name"
            value={formData.beneficiaryName}
            onChange={(e) => handleInputChange(e.target.value, "beneficiaryName")}
            required
          />
          {formErrors.beneficiaryName && (<span style={{ color: "red" }}>{formErrors.beneficiaryName}</span>)}
        </div>

        <div className="form_input">
          <label htmlFor="bankName">Bank Name:</label>
          <input
            type="text"
            id="bankName"
            name="bankName"
            className="propertyForm_input"
            placeholder="Enter Bank Name"
            value={formData.bankName}
            onChange={(e) => handleInputChange(e.target.value, "bankName")}
            required
          />
          {formErrors.bankName && (<span style={{ color: "red" }}>{formErrors.bankName}</span>)}
        </div>

        <div className="form_input">
          <label htmlFor="accountNumber">IBAN:</label>
          <input
            type="text"
            id="accountNumber"
            name="accountNumber"
            className="propertyForm_input"
            placeholder="Enter Account Number"
            value={formData.accountNumber}
            onChange={(e) => handleInputChange(e.target.value, "accountNumber")}
            required
          />
          {formErrors.accountNumber && (<span style={{ color: "red" }}>{formErrors.accountNumber}</span>)}
        </div>

        <div className="form_input">
          <label htmlFor="IFSC">BIC:</label>
          <input
            type="text"
            id="IFSC"
            name="IFSC"
            className="propertyForm_input"
            placeholder="Enter IFSC Code"
            value={formData.IFSC}
            onChange={(e) => handleInputChange(e.target.value, "IFSC")}
            required
          />
          {formErrors.IFSC && (<span style={{ color: "red" }}>{formErrors.IFSC}</span>)}
        </div>

      </form>

      <div>
        <button className="submit_city" type="submit" onClick={handleSubmit}>
          Submit
        </button>
        <button className="cancel_city" type="button" onClick={handleClear}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default BankForm;
