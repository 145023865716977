import React, { useEffect, useState } from "react";
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import axios from "axios";
import { base_url } from "../../constant";
import styled from "styled-components";
import ManualBookingForm from "./manualBookingForm";

const ManualBooking = () => {
    const [showForm, setShowForm] = useState(false);
    const [selectedManual, setSelectedManual] = useState(null);
    const [manualData, setManualData] = useState([]);

    const getManualData = async () => {
        const accessToken = localStorage.getItem("x-access-token");
        const refreshToken = localStorage.getItem("x-refresh-token");
        try {
            const res = await axios.get(`${base_url}/booking/manual-booking`, {
                headers: {
                    "x-access-token": accessToken,
                    "x-refresh-token": refreshToken,
                },
            });
            setManualData(res?.data?.data);
        } catch (error) {
            console.log("Error fetching booking data:", error);
        }
    };

    useEffect(() => {
        getManualData();
    }, []);

    const toggleFormVisibility = (ele = null) => {
        setSelectedManual(ele);
        setShowForm(!showForm);
    };

    const deleteManualData = async (id) => {
        const accessToken = localStorage.getItem("x-access-token");
        const refreshToken = localStorage.getItem("x-refresh-token");
        try {
            const res = await axios.delete(`${base_url}/booking/manual-booking?id=${id}`, {
                headers: {
                    "x-access-token": accessToken,
                    "x-refresh-token": refreshToken,
                },
            });
            if (res.status === 200) {
                alert("Manual Booking Deleted Successfully");
                getManualData();
            }
        } catch (error) {
            console.log("Error deleting coupon data:", error);
        }
    };

    const renderManualCards = () => {
        return manualData.map((ele) => (
            <Card key={ele.id}>
                <h3>Property Name: {ele?.Property?.name}</h3>
                <p>Booking From: {ele?.bookFrom}</p>
                <p>Booking To: {ele?.bookTo}</p>
                <div>
                    <Button2 onClick={() => toggleFormVisibility(ele)}>
                        <img src="/edit.svg" alt="edit" />
                    </Button2>
                    <Button2 onClick={() => deleteManualData(ele.id)} style={{ marginLeft: "30px" }}>
                        <img src="/trash-2 (1).svg" alt="delete" />
                    </Button2>
                </div>
            </Card>
        ));
    };

    return (
        <Container>
            <SidebarContainer>
                <SideBar />
            </SidebarContainer>
            <Content>
                <FirstNavbar />
                {!showForm && <Button onClick={() => toggleFormVisibility()}>Add Manual Booking</Button>}
                {showForm && (
                    <ManualBookingForm
                        initialData={selectedManual || {}}
                        toggleFormVisibility={toggleFormVisibility}
                    />
                )}
                {!showForm && <CardContainer>{renderManualCards()}</CardContainer>}
            </Content>
        </Container>
    );
};


const Container = styled.div`
    display: flex;
    justify-content: end;
    height: 100vh;
`;

const SidebarContainer = styled.div`
    width: 300px;
    background-color: #f0f0f0;
    padding: 20px;
`;

const Content = styled.div`
    width: 80%;
    padding: 20px;
`;

const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
`;

const Card = styled.div`
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    border-radius: 12px;
`;

const Button2 = styled.button`
    margin-bottom: 20px;
    border: none;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: none;
    &:hover {
        transform: scale(1.1);
    }
`;

const Button = styled.button`
    background-color: #0023b4;
    color: #fff;
    margin-bottom: 20px;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

export default ManualBooking;
