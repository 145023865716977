import React, { useEffect, useState } from "react";
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import CityForm from "./faqForm";
import axios from "axios";
import { base_url } from "../../constant";
import styled from "styled-components";
import FaqForm from "./faqForm";

const Container = styled.div`
  display: flex;
  justify-content:end;
  height: 100vh;
`;

const SidebarContainer = styled.div`
  width: 300px;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Content = styled.div`
  width:80%;
  padding: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding:10px;
  gap:10px;
`;

const Card = styled.div`
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:start;
  border-radius: 12px;
`;
const Button2 = styled.button`
  
  margin-bottom:20px;
  border: none;
  padding: 8px ;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background:none;
  &:hover {
   transform : scale(1.1);
  }
`;

const Button = styled.button`
  background-color: #0023B4;
  color: #fff;
  margin-bottom:20px;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;


const Faq = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [faqData, setFaqData] = useState([]);

  const getFaqData = async () => {
    try {
      const res = await axios.get(`${base_url}/faq`);
      console.log(res?.data?.data, "Data");
      setFaqData(res?.data?.data);
    } catch (error) {
      console.log(error, "Error");
    }
  };

  useEffect(() => {
    getFaqData();
  }, []);
  const accessToken = localStorage.getItem("x-access-token");
  const refreshToken = localStorage.getItem("x-refresh-token");
  const toggleFormVisibility = (faq = null) => {
    setSelectedFaq(faq);
    setShowForm(!showForm);
  };
  const handleDeleteFaq= async (id) => {
    let payload = {};
    payload.id = id;
    console.log(payload);
    try {
      const res = await axios.delete(`${base_url}/faq`, {
        data: payload,
        headers: {

          "x-access-token": accessToken,
          "x-refresh-token": refreshToken
        },
      });

      if(res.data.data){
        getFaqData();
      }
      
    } catch (error) {
      console.log('error while deleting faq', error);
    }
  }

  const renderFaqCards = () => {
    return faqData.map((faq) => (
      <Card key={faq.id}>
        <h3>Quesion : {faq.question}</h3>
        <p>Answer: {faq.answer}</p>
        <div >
          <Button2 ><img src="/edit.svg"  alt="edit"  onClick={() => toggleFormVisibility(faq)}/></Button2>
          <Button2 style={{marginLeft:'30px'}}><img src="/trash-2 (1).svg"  alt="delete"  onClick={() => handleDeleteFaq(faq.id)}/></Button2>
        </div>
      </Card>
    ));
  };

  return (
    <Container>
      <SidebarContainer>
        <SideBar />
      </SidebarContainer>
      <Content>
        <FirstNavbar />
        {!showForm && <Button onClick={() => toggleFormVisibility()}>Add Faq</Button>}
        {showForm && (
          <FaqForm
            initialData={
              selectedFaq
                ? {
                    ...selectedFaq,
                    documents: selectedFaq.documentNeeded,
                  }
                : null
            }
            toggleFormVisibility={toggleFormVisibility}
          />
        )}
        {!showForm && 
        <CardContainer>{renderFaqCards()}</CardContainer>}
      </Content>
    </Container>
  );
};

export default Faq;