import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { base_url } from '../../constant';
import ReactQuill from 'react-quill'

const AdminPanel = ({ toggleFormVisibility, selectedCity = null }) => {
  const initialData = {
    name: '',
    heroImage: "",
    heroImageUrl: '',
    about: '',
    photos: [], // Initialized as an array
    photosUrl: [], // Initialized as an array
    universities: [],
    tipImage: "",
    tipImageUrl: '',
    tipData: '',
    tipperName: '',
    tipperDesignation: '',
    food: [], // Initialized as an array
    places: [], // Initialized as an array
  };


  const [initialFormData, setInitialFormData] = useState(selectedCity || initialData);
  const [formData, setFormData] = useState(selectedCity || initialData);

  useEffect(() => {
    setInitialFormData(selectedCity || initialData);
  }, [selectedCity]);

  // Handles changes to basic input fields and file inputs.
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files && files[0]) {
      setFormData({
        ...formData,
        [name]: files[0],
        [`${name}Url`]: URL.createObjectURL(files[0]),
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handles array input changes (e.g., photos array).
  const handleArrayInputChange = async (e, fieldName, index) => {
    const { files } = e.target;
    
    if (files && files.length > 0) {
      const imageUrl = await uploadImageToBackend(files[0]);
      setFormData(prevData => ({
        ...prevData,
        [fieldName]: [
          ...prevData[fieldName].slice(0, index),
          imageUrl,
          ...prevData[fieldName].slice(index + 1)
        ]
      }));
    }
  };

  const addPhoto = () => {
    setFormData(prevData => ({
      ...prevData,
      photos: [...prevData.photos, null],
    }));
  };

  const removePhoto = (index) => {
    setFormData(prevData => {
      const updatedPhotos = [...prevData.photos];
      updatedPhotos.splice(index, 1);
      return { ...prevData, photos: updatedPhotos,};
    });
  };


  // Handles object input changes for nested fields (e.g., food and places).
  const handleObjectInputChange = (e, fieldName, index, key) => {
    const { value, files } = e.target;
    setFormData(prevData => {
      const updatedArray = [...prevData[fieldName]];
      if (key === 'image' && files && files.length > 0) {
        // Handle file upload if needed
      } else {
        updatedArray[index] = { ...updatedArray[index], [key]: value };
      }
      return { ...prevData, [fieldName]: updatedArray };
    });
  };

  // New handler for adding a university
  const addUniversity = () => {
    setFormData(prevData => ({
      ...prevData,
      universities: [...prevData.universities, ''],
    }));
  };

  // New handler for removing a university
  const removeUniversity = (index) => {
    setFormData(prevData => {
      const updatedUniversities = [...prevData.universities];
      updatedUniversities.splice(index, 1);
      return { ...prevData, universities: updatedUniversities };
    });
  };

  // New handler for updating a university
  const handleUniversityChange = (index, value) => {
    setFormData(prevData => {
      const updatedUniversities = [...prevData.universities];
      updatedUniversities[index] = value;
      return { ...prevData, universities: updatedUniversities };
    });
  };

  // Handles image uploads to the backend.
  const uploadImageToBackend = async (image) => {
    try {
      const imageFormData = new FormData();
      imageFormData.append('image', image);
      const res = await axios.post(`${base_url}/uploadImage`, imageFormData);
      return res.data.path;
    } catch (error) {
      console.error(error?.response);
    }
  };

  // Handles adding new food items to the form data.
  const addFood = () => {
    setFormData(prevData => ({
      ...prevData,
      food: [...prevData.food, { name: '', image: null, googleMapLink: '', rating: '' }],
    }));
  };

  const removeFood = (index) => {
    setFormData(prevData => {
      const updatedFood = [...prevData.food];
      updatedFood.splice(index, 1);
      return { ...prevData, food: updatedFood };
    });
  };

  // Handles adding new place items to the form data.
  const addPlace = () => {
    setFormData({
      ...formData,
      places: [...formData.places, { name: '', image: null, googleMapLink: '', rating: '' }],
    });
  };

  // Handles removing place items.
  const removePlace = (index) => {
    const updatedPlaces = [...formData.places];
    updatedPlaces.splice(index, 1);
    setFormData({ ...formData, places: updatedPlaces });
  };

  // Handles form submission, performing POST or PUT based on the presence of an ID.
  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem("x-access-token");
    const refreshToken = localStorage.getItem("x-refresh-token");

    let payload = {};

    const isDifferent = (a, b) => JSON.stringify(a) !== JSON.stringify(b);

    Object.keys(formData).forEach(key => {
      if (isDifferent(formData[key], initialFormData[key])) {
        if (key === 'food' || key === 'places') {
          payload[key] = formData[key].map(item => ({
            id: item.id, // Include the id if it exists
            name: item.name,
            image: item.image,
            googleMapLink: item.googleMapLink,
            rating: item.rating
          }));
          
          // If any items were deleted, include their IDs in a separate array
          const deletedItems = initialFormData[key].filter(initialItem => 
            !formData[key].some(currentItem => currentItem.id === initialItem.id)
          );
          if (deletedItems.length > 0) {
            payload[`deleted${key.charAt(0).toUpperCase() + key.slice(1)}Ids`] = deletedItems.map(item => item.id);
          }
        } else if (key !== 'photos' && key !== 'photosUrl') {
          payload[key] = formData[key];
        }
      }
    });

    if (formData.id) {
      payload.id = formData.id;
    }

    // Handle heroImage, tipImage, and photos only if they've changed
    if (isDifferent(formData.heroImage, initialFormData.heroImage)) {
      payload.heroImage = formData.heroImage;
    }
    if (isDifferent(formData.tipImage, initialFormData.tipImage)) {
      payload.tipImage = formData.tipImage;
    }
    if (isDifferent(formData.photos, initialFormData.photos)) {
      payload.photos = formData.photos;
    }

    console.log("Payload before sending:", payload); // Log the payload for debugging

    try {
      const response = formData.id
        ? await axios.put(`${base_url}/aroundTown`, payload, {
            headers: {
              "x-access-token": accessToken,
              "x-refresh-token": refreshToken,
            },
          })
        : await axios.post(`${base_url}/aroundTown`, payload, {
            headers: {
              "x-access-token": accessToken,
              "x-refresh-token": refreshToken,
            },
          });

      if (response.status === 201 || response.status === 200) {
        alert(formData.id ? "AroundTown Updated successfully" : "AroundTown Added successfully");
        console.log("Form data sent successfully:", response.data.data);
        toggleFormVisibility();
      }
    } catch (error) {
      console.error("Error sending form data:", error.response || error);
    }
  };



  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {/* Name Field */}
        <InputWrapper>
          <Label>Name:</Label>
          <Input
            id="name"
            type="text"
            className="propertyForm_input"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Name"
          />
        </InputWrapper>

        {/* Hero Image Field */}
        <InputWrapper>
          <Label>Hero Image:</Label>
          <Input
            id="heroImage"
            type="file"
            name="heroImage"
            onChange={(e) => {
              handleInputChange(e);
              if (e.target.files && e.target.files.length > 0) {
                uploadImageToBackend(e.target.files[0])
                  .then((imageUrl) => setFormData({ ...formData, heroImageUrl: imageUrl }))
                  .catch((error) => console.error(error?.response));
              } else {
                setFormData({ ...formData, heroImageUrl: '' });
              }
            }}
            placeholder="Hero Image"
          />
        </InputWrapper>

        {/* Displaying Selected Hero Image */}
        {formData.heroImage && <img src={formData.heroImage} width={140} height={140} alt="heroImage" />}

        {/* About Field */}
        <InputWrapper>
          <Label>About:</Label>
          <Textarea
            id="about"
            name="about"
            className="propertyForm_input"
            style={{ height: '60px' }}
            value={formData.about}
            onChange={handleInputChange}
            placeholder="About"
          />
        </InputWrapper>

        {/* Photos Section */}
        <FieldGroup>
          <Label>Photos:</Label>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {formData.photos.map((photo, index) => (
              <InputWrapper key={index}>
                <Input
                  id={`photos[${index}]`}
                  type="file"
                  name={`photos[${index}]`}
                  onChange={(e) => handleArrayInputChange(e, 'photos', index)}
                  placeholder={`Photo ${index + 1}`}
                />
                {formData.photos[index] && (
                  <img
                    src={formData.photos[index]}
                    alt={`Photo-logo ${index + 1}`}
                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                  />
                )}
                <Button type="button" onClick={() => removePhoto(index)}>
                  Remove
                </Button>
              </InputWrapper>
            ))}
          </div>
          <Button type="button" onClick={addPhoto}>
            Add Photo
          </Button>
        </FieldGroup>

        {/* Universities Field */}
        <FieldGroup>
          <Label>Universities:</Label>
          {formData.universities.map((university, index) => (
            <InputWrapper key={index}>
              <Input
                type="text"
                value={university}
                onChange={(e) => handleUniversityChange(index, e.target.value)}
                placeholder="University name"
              />
              <Button type="button" onClick={() => removeUniversity(index)}>
                Remove
              </Button>
            </InputWrapper>
          ))}
          <Button type="button" onClick={addUniversity}>
            Add University
          </Button>
        </FieldGroup>

        {/* Tip Image Field */}
        <InputWrapper>
          <Label>Tip Image:</Label>
          <Input
            id='tipImage'
            type="file"
            name="tipImage"
            onChange={(e) => {
              handleInputChange(e);
              if (e.target.files && e.target.files.length > 0) {
                uploadImageToBackend(e.target.files[0])
                  .then((imageUrl) => setFormData({ ...formData, tipImageUrl: imageUrl }))
                  .catch((error) => console.error(error?.response));
              } else {
                setFormData({ ...formData, tipImageUrl: '' });
              }
            }}
            placeholder="Tip Image"
          />
        </InputWrapper>

        {/* Displaying Selected Tip Image */}
        {formData.tipImage && <img src={formData.tipImage} width={140} height={140} alt="tipImageUrl" />}

        {/* Tip Data Section */}
        <InputWrapper>
          <Label>Tip Data:</Label>
          <Textarea
            id="tipData"
            name="tipData"
            className="propertyForm_input"
            style={{ height: '60px' }}
            value={formData.tipData}
            onChange={handleInputChange}
            placeholder="Tip Data"
          />
        </InputWrapper>

        {/* Tipper Name Field */}
        <InputWrapper>
          <Label>Tipper Name:</Label>
          <Input
            id="tipperName"
            type="text"
            className="propertyForm_input"
            name="tipperName"
            value={formData.tipperName}
            onChange={handleInputChange}
            placeholder="Tipper Name"
          />
        </InputWrapper>

        {/* Tipper Designation Field */}
        <InputWrapper>
          <Label>Tipper Designation:</Label>
          <Input
            id="tipperDesignation"
            type="text"
            className="propertyForm_input"
            name="tipperDesignation"
            value={formData.tipperDesignation}
            onChange={handleInputChange}
            placeholder="Tipper Designation"
          />
        </InputWrapper>

        {/* Food Section */}
        {formData.food.map((foodItem, index) => (
          <div key={index}>
            <InputWrapper>
              <Label>Food Name:</Label>
              <Input
                id={`food[${index}].name`}
                type="text"
                name={`food[${index}].name`}
                value={foodItem.name}
                onChange={(e) => handleObjectInputChange(e, 'food', index, 'name')}
                placeholder="Food Name"
              />
            </InputWrapper>
              <InputWrapper>
                <Label>Food Image:</Label>
                <Input
                  id={`food[${index}].image`}
                  type="file"
                  name={`food[${index}].image`}
                  onChange={(e) => handleObjectInputChange(e, 'food', index, 'image')}
                  placeholder="Food Image"
                />
                {foodItem?.image && <img src={foodItem?.image} width={140} height={140} alt="tipImageUrl" />}
              </InputWrapper>
              <InputWrapper>
                <Label>Google Map Link:</Label>
                <Input
                  id={`food[${index}].googleMapLink`}
                  type="url"
                  name={`food[${index}].googleMapLink`}
                  value={foodItem.googleMapLink}
                  onChange={(e) => handleObjectInputChange(e, 'food', index, 'googleMapLink')}
                  placeholder="Google Map Link"
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Rating:</Label>
                <Input
                  id={`food[${index}].rating`}
                  type="text"
                  name={`food[${index}].rating`}
                  value={foodItem.rating}
                  onChange={(e) => handleObjectInputChange(e, 'food', index, 'rating')}
                  placeholder="Rating"
                />
              </InputWrapper>
              <Button type="button" onClick={() => removeFood(index)}>
                Remove Food
              </Button>
            </div>
          ))}
        <Button type="button" onClick={addFood}>
          Add Food
        </Button>

        {/* Places Section */}
        {formData?.places?.length > 0 &&
          formData?.places.map((place, index) => (
            <div key={index}>
              <InputWrapper>
                <Label>Place Name:</Label>
                <Input
                  id={`places[${index}].name`}
                  type="text"
                  name={`places[${index}].name`}
                  value={place.name}
                  onChange={(e) => handleObjectInputChange(e, 'places', index, 'name')}
                  placeholder="Place Name"
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Place Image:</Label>
                <Input
                  id={`places[${index}].image`}
                  type="file"
                  name={`places[${index}].image`}
                  onChange={(e) => handleObjectInputChange(e, 'places', index, 'image')}
                  placeholder="Place Image"
                />
                {place?.image && <img src={place?.image} width={140} height={140} alt="tipImageUrl" />}
              </InputWrapper>
              <InputWrapper>
                <Label>Google Map Link:</Label>
                <Input
                  id={`places[${index}].googleMapLink`}
                  type="url"
                  name={`places[${index}].googleMapLink`}
                  value={place.googleMapLink}
                  onChange={(e) => handleObjectInputChange(e, 'places', index, 'googleMapLink')}
                  placeholder="Google Map Link"
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Rating:</Label>
                <Input
                  id={`places[${index}].rating`}
                  type="text"
                  name={`places[${index}].rating`}
                  value={place.rating}
                  onChange={(e) => handleObjectInputChange(e, 'places', index, 'rating')}
                  placeholder="Rating"
                />
              </InputWrapper>
              <Button type="button" onClick={() => removePlace(index)}>
                Remove Place
              </Button>
            </div>
          ))}
        <Button type="button" onClick={addPlace}>
          Add Place
        </Button>

        {/* Form Buttons */}
        <Button type="submit">Submit</Button>
        <Button type="button" onClick={toggleFormVisibility}>
          Cancel
        </Button>
      </Form>
    </Container>
  );
};

export default AdminPanel;



const Container = styled.div`
max-width: 1000px;
margin: 0 auto;
padding: 20px;
`;

const Form = styled.form`
display: grid;
gap: 20px;
`;

const InputWrapper = styled.div`
display: flex;
align-items: center;
gap: 10px;
`;

const Label = styled.label`
flex-basis: 150px;
font-weight: bold;
`;

const Input = styled.input`
padding: 10px;
font-size: 16px;
flex-grow: 1;
`;

const Textarea = styled.textarea`
padding: 10px;
font-size: 16px;
resize: vertical;
flex-grow: 1;
`;

const Button = styled.button`
padding: 10px 20px;
font-size: 16px;
background-color: #007bff;
color: #fff;
border: none;
cursor: pointer;
`;

const FieldGroup = styled.div`
display: grid;
gap: 10px;
`;
