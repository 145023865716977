import React, { useEffect, useState } from "react";
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import axios from "axios";
import { base_url } from "../../constant";
import { Table, Button } from "react-bootstrap";

const Replacement = () => {
    const [data, setData] = useState([]);
    const [active, setActive] = useState("group");
    const [selectedId, setSelectedId] = useState("");
    const [names, setNames] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [replacementID, setReplacementID] = useState("");
    const [selectedNameId, setSelectedNameId] = useState("");
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [bookingStatus, setBookingStatus] = useState("");
    const [formData, setFormData] = useState({
        replacementName: "",
        replacementEmail: "",
        replacementPassport: "",
        replacementReason: "",
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [docData, setDocData] = useState(null);
    const [flag, setFlag] = useState(true);
    const [replacementDetails, setReplacementDetails] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');

    const statusOptions = [
        "document_uploaded",
        "loiSigned",
        "serviceFeePaid",
        "contractSigned",
        "finalpayment"
    ];
    const getStatus = (status) => {
        switch (status) {
            case 'document_uploaded':
                return 'document';
            case 'loiSigned':
                return 'loi';
            case 'serviceFeePaid':
                return 'serviceFee';
            case 'contractSigned':
                return 'contract';
            case 'finalFeePaid':
                return 'final';
            default:
                return 'bg-gray-200';
        }
    };

    useEffect(() => {
        getBookingList();
        getReplacementDetails();
    }, []);

    useEffect(() => {
        if (selectedId) {
            getReplacementDetails(selectedId);
        }
    }, [selectedId]);
    const handleBookingFilter = (filter) => {
        setActive(filter);
    };

    const getBookingList = async () => {
        try {
            const accessToken = localStorage.getItem("x-access-token");
            const refreshToken = localStorage.getItem("x-refresh-token");
            const res = await axios.get(`${base_url}/booking/adminOngoingBookingList`, {
                headers: {
                    "x-access-token": accessToken,
                    "x-refresh-token": refreshToken,
                },
            });
            const sortedData = res?.data?.data.sort((a, b) => a.createdAt < b.createdAt);
            setData(sortedData);
        } catch (error) {
            console.log(error);
        }
    };

    const handleIdChange = async (event) => {
        const id = event.target.value;
        setSelectedId(id);
        if (!id) {
            setNames([]);
            setSelectedNameId("");
            return;
        }

        try {
            const accessToken = localStorage.getItem("x-access-token");
            const refreshToken = localStorage.getItem("x-refresh-token");
            const res = await axios.get(`${base_url}/booking/getBookingSingle?id=${id}`, {
                headers: {
                    "x-access-token": accessToken,
                    "x-refresh-token": refreshToken,
                },
            });

            const tenants = res?.data?.tentants;
            const namesList = tenants ? tenants.map((tenant) => ({ id: tenant.id, name: tenant.name })) : [];
            setNames(namesList);
            setSelectedNameId("");
            setIsInputVisible(false);
        } catch (error) {
            console.log("Error fetching details for ID:", id, error);
        }
    };

    const handleNameChange = (event) => {
        const nameId = event.target.value;
        setSelectedNameId(nameId);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: "",
        }));
    };

    const handleReplacementClick = () => {
        if (selectedId && selectedNameId) {
            setIsInputVisible(true);
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.replacementName) formErrors.replacementName = "Replacement Name is required";
        if (!formData.replacementEmail) formErrors.replacementEmail = "Replacement Email is required";
        if (!formData.replacementPassport) formErrors.replacementPassport = "Replacement Passport is required";
        if (!formData.replacementReason) formErrors.replacementReason = "Replacement Reason is required";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSave = async () => {
        if (validateForm()) {
            try {
                const accessToken = localStorage.getItem("x-access-token");
                const refreshToken = localStorage.getItem("x-refresh-token");
                const payload = {
                    booking: selectedId,
                    name: formData.replacementName,
                    email: formData.replacementEmail,
                    passport: formData.replacementPassport,
                    reason: formData.replacementReason,
                    replaceFrom: selectedNameId,
                };

                const response = await axios.post(`${base_url}/booking/replacement`, payload, {
                    headers: {
                        "x-access-token": accessToken,
                        "x-refresh-token": refreshToken,
                    },
                });
                if (response.status === 200) {
                    setIsSaved(true);
                    setIsInputVisible(true);
                    setSelectedId("");
                    setNames("");
                    setFormData({
                        replacementName: "",
                        replacementEmail: "",
                        replacementPassport: "",
                        replacementReason: "",
                    });
                    const docs = response.data.documentsRequired[0][0].documentNeeded;
                    const bookingData = response.data.bookingData;
                    setBookingStatus(bookingData.status);
                    setReplacementID(response?.data?.data?.id);
                    setDocData(Array.isArray(docs) ? docs : [docs]);
                }
            } catch (error) {
                console.error("Error saving replacement:", error);
            }
        }
    };

    const handleImageUpload = async (docName, e, docId) => {
        try {
            const accessToken = localStorage.getItem("x-access-token");
            const refreshToken = localStorage.getItem("x-refresh-token");
            const files = Array.from(e.target.files);
            setImageUrl(files);
            const formData = new FormData();
            formData.append("document", ...files);
            formData.append("documentName", docName);
            formData.append("replacementId", replacementID);

            const response = await axios.post(`${base_url}/booking/replacement-document`, formData, {
                headers: {
                    "x-access-token": accessToken,
                    "x-refresh-token": refreshToken,
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                alert("Document uploaded successfully");
                setImageUrl(null);
            }
        } catch (error) {
            console.error("Error uploading images:", error);
        }
    };

    const getReplacementDetails = async (id) => {
        try {
            const accessToken = localStorage.getItem("x-access-token");
            const refreshToken = localStorage.getItem("x-refresh-token");

            const payload = {
                booking: selectedId,
            };

            const response = await axios.get(`${base_url}/booking/replacement`, {
                headers: {
                    "x-access-token": accessToken,
                    "x-refresh-token": refreshToken,
                },
                params: payload,
            });
            setReplacementDetails(response.data.data);
        } catch (error) {
            console.log("Error", error);
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFile(file);
        }
    };

    const updateStatus = async (id, newStatus) => {
        if (newStatus === "serviceFeePaid" || newStatus === "finalpayment") {
            setShowPopup(true);
            setSelectedId(id);
            setSelectedStatus(newStatus);
            return;
        }
        try {
            const accessToken = localStorage.getItem("x-access-token");
            const refreshToken = localStorage.getItem("x-refresh-token");
            const payload = {
                replacementId: id,
                status: newStatus
            };

            const response = await axios.post(`${base_url}/booking/replacement-status`, payload, {
                headers: {
                    "x-access-token": accessToken,
                    "x-refresh-token": refreshToken,
                },
            });
            if (response.status === 200) {
                alert("Status updated successfully");
                getBookingList();
            }
        } catch (error) {
            console.error("Error booking status:", error);
        }
    };

    const handleSubmit = async () => {
        if (selectedId && selectedStatus) {
            try {
                const accessToken = localStorage.getItem("x-access-token");
                const refreshToken = localStorage.getItem("x-refresh-token");

                const formData = new FormData();
                if (selectedStatus === "serviceFeePaid") {
                    formData.append('serviceFeeProof', uploadedFile);
                } else if (selectedStatus === "finalpayment") {
                    formData.append('finalPaymentProof', uploadedFile);
                }
                formData.append('replacementId', selectedId);
                formData.append('status', selectedStatus);

                const response = await axios.post(`${base_url}/booking/replacement-status`, formData, {
                    headers: {
                        "x-access-token": accessToken,
                        "x-refresh-token": refreshToken,
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (response.status === 200) {
                    setUploadedFile(null);
                    alert("Payment Proof is uploaded successfully");
                    setShowPopup(false);
                    getBookingList();
                }
            } catch (error) {
                console.error("Error Payment Proof:", error);
            }
        }
    };


    return (
        <div className="mainContainer">
            <div className="sidebar">
                <SideBar />
            </div>
            <div className="content" style={{ width: "75%" }}>
                <div className="container">
                    <FirstNavbar />
                    <div>
                        <button className={`btn-booking ${active === "group" ? "active" : ""}`} onClick={() => handleBookingFilter("group")}>
                            Group Booking
                        </button>
                    </div>
                    <div>
                        <button className="btn-booking" onClick={() => { setFlag(true); }}> Replace Tenant </button>
                        <button className="btn-booking" onClick={() => { setFlag(false); }}> Replace details </button>
                    </div>
                    {flag ? (
                        <>
                            <div style={{ marginTop: "20px" }}>
                                <div htmlFor="bookingIdSelect">Select Booking ID:</div>
                                <select id="bookingIdSelect" className="propertyForm_input" value={selectedId} onChange={handleIdChange}>
                                    <option value="">Select ID</option>
                                    {data.filter((item) => item.type === active).map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.bookingUniqueId}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <div htmlFor="bookingNameSelect">Tenant Names:</div>
                                <select id="bookingNameSelect" className="propertyForm_input" value={selectedNameId} onChange={handleNameChange} disabled={!selectedId}>
                                    <option value="">Select Name</option>
                                    {names.length > 0 ? (
                                        names.map((tenant) => (
                                            <option key={tenant.id} value={tenant.id}>
                                                {tenant.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">Select a Booking Name first</option>
                                    )}
                                </select>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <button className={`${!selectedId || !selectedNameId ? "button" : "button2"}`} onClick={handleReplacementClick} disabled={!selectedId || !selectedNameId}>
                                    Replacement
                                </button>
                            </div>
                            {isInputVisible && (
                                <div>
                                    <div className="replacement-div">
                                        <div>
                                            <div htmlFor="replacementName">Replacement Name:</div>
                                            <input id="replacementName" className="propertyForm_input" type="text" value={formData.replacementName} onChange={handleInputChange} placeholder="Enter replacement name" />
                                            {errors.replacementName && <p className="error">{errors.replacementName}</p>}
                                        </div>
                                        <div>
                                            <div htmlFor="replacementEmail">Replacement Email:</div>
                                            <input className="propertyForm_input" id="replacementEmail" type="email" value={formData.replacementEmail} onChange={handleInputChange} placeholder="Enter replacement email" />
                                            {errors.replacementEmail && <p className="error">{errors.replacementEmail}</p>}
                                        </div>
                                        <div>
                                            <div htmlFor="replacementPassport">Replacement Passport:</div>
                                            <input className="propertyForm_input" id="replacementPassport" type="text" value={formData.replacementPassport} onChange={handleInputChange} placeholder="Enter replacement passport" />
                                            {errors.replacementPassport && <p className="error">{errors.replacementPassport}</p>}
                                        </div>
                                        <div>
                                            <div htmlFor="replacementReason">Type of Tenant:</div>
                                            <select id="replacementReason" className="propertyForm_input" value={formData.replacementReason} onChange={handleInputChange}>
                                                <option value="">Select</option>
                                                <option value="Student">Student</option>
                                                <option value="Working Professional">Working Professional</option>
                                                <option value="Others">Others</option>
                                            </select>
                                            {errors.replacementReason && <p className="error">{errors.replacementReason}</p>}
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        {!isSaved ? (
                                            <button className="button2" onClick={handleSave} disabled={isSubmitting}>
                                                {isSubmitting ? "Saving..." : "Save"}
                                            </button>
                                        ) : (
                                            <div>
                                                {bookingStatus !== "tenant_details" && (
                                                    <>
                                                        <h2>Replacement Saved Successfully!</h2>
                                                        {docData && (
                                                            <div className="document-data">
                                                                <h3>Required Documents:</h3>
                                                                {Array.isArray(docData) ? (
                                                                    <ul>
                                                                        {docData.map((doc, index) => (
                                                                            <div key={index}>
                                                                                <div>{doc.name}</div>
                                                                                <div>
                                                                                    <input type="file" multiple id={`images-${doc.id}`} name={`images-${doc.id}`} accept="image/*" onChange={(e) => handleImageUpload(doc.name, e, doc.id)} />
                                                                                </div>
                                                                                {imageUrl && imageUrl[doc.id] && <img alt="img" src={URL.createObjectURL(imageUrl)} />}
                                                                            </div>
                                                                        ))}
                                                                    </ul>
                                                                ) : (
                                                                    <p>{docData}</p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Passport</th>
                                        <th>Reason</th>
                                        <th>BookingID</th>
                                        <th>Replace From</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {replacementDetails?.map((detail) => (
                                        <tr key={detail.id}>
                                            <td>{detail.name}</td>
                                            <td>{detail.email}</td>
                                            <td>{detail.passport}</td>
                                            <td>{detail.reason}</td>
                                            <td>{detail.Booking?.bookingUniqueId}</td>
                                            <td>{detail.replaceFromTenant?.name}</td>
                                            <td>
                                                {detail.status === "pending" ? (
                                                    <select
                                                        value={detail.bookingStatus}
                                                        onChange={(e) => updateStatus(detail.id, e.target.value)}
                                                        className={`status_select ${getStatus(detail.bookingStatus)}`}
                                                    >
                                                        <option value={detail.bookingStatus}>{detail.bookingStatus}</option>
                                                        {detail?.bookingStatus === "loiSigned" || detail?.bookingStatus === "contractSigned" ?
                                                            <>
                                                                {detail?.bookingStatus === "loiSigned" ?
                                                                    <option value='serviceFeePaid'>serviceFeePaid</option>
                                                                    :
                                                                    <option value='finalpayment'>finalPayment</option>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {detail?.bookingStatus === "serviceFeePaid" || detail?.bookingStatus === "finalFeePaid" ? 
                                                                    <>{detail?.bookingStatus}</>
                                                                    :
                                                                    <>{statusOptions
                                                                        .slice(statusOptions.indexOf(detail.bookingStatus) + 1)
                                                                        .map((status) => (
                                                                            <option key={status} value={status}>
                                                                                {status}
                                                                            </option>
                                                                        ))}
                                                                    </>
                                                                }

                                                            </>
                                                        }
                                                    </select>
                                                ) : (
                                                    detail?.bookingStatus
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </div>
                {showPopup && (
                    <div className="popup">
                        <div className="popup-inner">
                            <div>
                                <input type="file" onChange={handleFileUpload} />
                                <Button onClick={handleSubmit}>Submit</Button>
                            </div>
                            <Button onClick={() => setShowPopup(false)}>Close</Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Replacement;
