import { useState } from "react";
import "./custom.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/dashboard/Main";

import TeamList from "./pages/about/TeamList";
import AddTeam from "./pages/about/AddTeam";
import Login from "./pages/auth/Login";
import ProductCareer from "./pages/Career/ProductCareer";
import AddCareer from "./pages/Career/AddCaree";
import CareerList from "./pages/Career/CareerList";
import JobApplyList from "./pages/Career/JobApplyList";
import Booking from "./pages/Booking/booking";
import City from "./pages/City/city";
import University from "./pages/University/university";
import Property from "./pages/Property/property";
import BookingDetails from "./pages/Booking/bookingDetails";
import OngoingBookingList from "./pages/Booking/ongoingBookingList";
import CurrentBookingList from "./pages/Booking/currentBooking";
import PastBookingList from "./pages/Booking/pastBookingDetails";
import AroundTown from "./pages/aroundTown/aroundtown";
import Enquiry from "./pages/enquiry";
import CancelBookingModal from "./pages/Booking/cancelBooking";
import CancelledBookingList from "./pages/Booking/cancelledBookingList";
import Blog from "./pages/Blog/blog";
import Bank from "./pages/Bank/bank";
import Faq from "./pages/faq/Faq";
import TalkToUs from "./pages/TalkToUs/TalkToUs";
import ContactUs from "./pages/contact/ContactUs";
import Subscribe from "./pages/subscribe/Subscribe";
import Replacement from "./pages/replacement/Replacement";
import Coupon from "./pages/Coupon/coupon";
import ManualBooking from "./pages/ManualBooking/manualBooking";

function App() {
  return (
    <BrowserRouter>
      <Routes>      
      <Route path="/" element={<Login />} />  
        <Route path="/dashboard" element={<Main />} />
 
        <Route path="/blog" element={<Blog />} />
        <Route path="/coupon" element={<Coupon />} />
        <Route path="/bank" element={<Bank />} />
        <Route path="/product-career" element={<ProductCareer />} />
        <Route path="/manualBooking" element={<ManualBooking />} />
        <Route path="/career-add" element={<AddCareer />} />
        <Route path="/career-list" element={<CareerList />} />
        <Route path="/JobApplyList" element={<JobApplyList />} />        
        <Route path="/about-team" element={<TeamList />} />
        <Route path="/about-add-team" element={<AddTeam />} />        
        <Route path="/booking" element={<Booking />} />
        <Route path="/bookingDetails" element={<BookingDetails />} />
        <Route path="/replacement" element={<Replacement />} />
        <Route path="/city" element={<City />} />
        <Route path="/university" element={<University />} />
        <Route path="/property" element={<Property />} />
        <Route path="/ongoingBookings" element={<OngoingBookingList />} />
        <Route path="/currentBookings" element={<CurrentBookingList />} />
        <Route path="/cancelledBookings" element={<CancelledBookingList />} />
        <Route path="/pastBookings" element={<PastBookingList />} />
        <Route path="/aroundTown" element={<AroundTown />} />
        <Route path="/enquiry" element={<Enquiry/>} />
        <Route path="/faq" element={<Faq/>}/>
        <Route path="/talkToUs" element={<TalkToUs/>}/>
        <Route path="/contactus" element={<ContactUs/>}/>
        <Route path="/subscribe" element={<Subscribe />}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
