import { Link } from "react-router-dom";
import logo from "./../../assets/images/xacco_logo.svg";

function SideBar() {
  const menuItems = [
    { path: "/booking", label: "Booking" },
    { path: "/property", label: "Property" },
    { path: "/city", label: "City" },
    { path: "/university", label: "University" },
    { path: "/aroundTown", label: "Around Town" },
    { path: "/coupon", label: "Coupon" },
    { path: "/manualBooking", label: "Manual Booking" },
    { path: "/replacement", label: "Replacement Tenant" },
    { path: "/enquiry", label: "Enquiry" },
    { path: "/blog", label: "Blog List" },
    { path: "/bank", label: "Bank Details" },
    { path: "/faq", label: "FAQ" },
    { path: "/talkToUs", label: "Talk To Us" },
    { path: "/contactus", label: "Contact Us" },
    { path: "/subscribe", label: "Subscribe" },
  ];

  return (
    <div className="sidebar">
      <div className="text-center">
        <img src={logo} width="80%" height="80%" alt="Logo" />
      </div>
      <div>
        {menuItems.map((item, index) => (
          <p key={index} className="cursor fs-5">
            <Link to={item.path}>{item.label}</Link>
          </p>
        ))}
      </div>
    </div>
  );
}

export default SideBar;
