import React, { useEffect, useState } from "react";
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import PropertyForm from "./propertyForm";
import axios from "axios";
import { base_url } from "../../constant";
import styled from "styled-components";


const Property = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyData, setPropertyData] = useState([]);

  const getPropertyData = async () => {
    try {
      const res = await axios.get(`${base_url}/property`);
      setPropertyData(res?.data?.data);
    } catch (error) {
      console.log(error, "Error");
    }
  };

  useEffect(() => {
    getPropertyData();
  }, []);
  const handleDeleteProperty= async (id) => {
    const accessToken = localStorage.getItem("x-access-token");
    const refreshToken = localStorage.getItem("x-refresh-token");
    try {
      const res = await axios.delete(`${base_url}/property?id=${id}`,{
        headers: {
            "x-access-token": accessToken,
            "x-refresh-token": refreshToken,
        },
      });
      if(res.status === 200){
        getPropertyData();
      }
      
    } catch (error) {
      console.log('error while deleting property', error);
    }
  }


  const toggleFormVisibility = (property = null) => {
    setSelectedProperty(property);
    setShowForm(!showForm);
  };

  const renderPropertyCards = () => {
    return propertyData.map((property) => (
      <Card key={property.id} >
        <h3>{property.name}</h3>
        <p>Type: {property.type}</p>
        <p>Building Type: {property.buildingType}</p>
        <div>
          <Button2 ><img src="/edit.svg" alt="edit"  onClick={() => toggleFormVisibility(property)}/></Button2>
          <Button2  style={{marginLeft:'30px'}}><img src="/trash-2 (1).svg" alt="delete" onClick={() => handleDeleteProperty(property.id)}/></Button2>
        </div>
      </Card>
    ));
  };

  return (
    <Container>
      <SidebarContainer>
        <SideBar />
      </SidebarContainer>
      <Content>
        <FirstNavbar />
        {!showForm && <Button onClick={() => toggleFormVisibility()}>Add Property</Button>}
        {showForm && (
          <PropertyForm
            initialData={selectedProperty}
            toggleFormVisibility={toggleFormVisibility}
          />
        )}
        {!showForm && 
        <CardContainer>{renderPropertyCards()}</CardContainer>}
      </Content>
    </Container>
  );
};

export default Property;


const Container = styled.div`
  display: flex;
 justify-content: end;
  height: 100vh;
`;

const SidebarContainer = styled.div`
  width:300px;
   background: #F5F5F5;
  padding: 20px;
`;

const Content = styled.div`
  width:80%;
  padding: 20px;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
`;

const Card = styled.div`
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:start;
  border-radius: 12px;
`;
const Button = styled.button`
  background-color: #0023B4;
  color: #fff;
  margin-bottom:20px;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;


const Button2 = styled.button`
  
  margin-bottom:20px;
  border: none;
  padding: 8px ;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background:none;
  &:hover {
   transform : scale(1.1);
  }
`;