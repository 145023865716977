import React, { useState, useEffect } from "react";
import Minus from "./../../assets/images/minus.jpg";
import Plus from "./../../assets/images/plus.png";
import { base_url } from "../../constant";
import axios from "axios";

const CityForm = ({ initialData = null, toggleFormVisibility,getCityData }) => {

  const initialFormData = {
    name: "",
    country: "",
    countryCode: "",
    currencyIcon: "",
    cardImage: "",
    cityIcon: "",
    currencyCode: "",
    about: "",
    images: [],
    documents: [{ name: "", description: "" }],
    maxmDaysToBookProperty: "",
    priceDec: [{ month: "", percentage: "" }],
  };

  const [formData, setFormData] = useState(initialData || initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [priceInc, setPriceInc] = useState([]);
  const [priceDec, setPriceDec] = useState(initialData?.priceDec || [{ month: "", percentage: "" }]);
  const [changedFields, setChangedFields] = useState({});

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        images: initialData.images,
        cardImage: initialData?.cardImage,
        documents: initialData.documentNeeded,
        cityIcon: initialData?.cityIcon,
        priceDec: initialData.priceDec || [{ month: "", percentage: "" }],
      });
      setPriceInc(initialData.priceInc);
      setPriceDec(initialData.priceDec || [{ month: "", percentage: "" }]);
    }
  }, [initialData]);

  const fetchCountriesCurrency = async (countryCode) => {
    try {
      const response = await axios.get(
        `https://restcountries.com/v3.1/alpha?codes=${countryCode}`
      );
      const countryData = response.data[0];
      const currency = countryData.currencies;
      return currency;
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://restcountries.com/v3.1/all?fields=name,cioc"
        );
        const countryData = response.data.map((country) => ({
          commonName: country.name.common,
          countryCode: country.cioc,
        }));
        countryData.sort((a, b) => a.commonName.localeCompare(b.commonName));
        setCountries(countryData);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.country.trim()) {
      errors.country = "Country is required";
      isValid = false;
    }

    if (!formData.about) {
      errors.about = "About is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleChange = async (e, index) => {
    const { name, value } = e.target;

    if (index !== undefined && index !== null && (name === "name" || name === "description")) {
      const updatedDocuments = [...formData.documents];
      updatedDocuments[index][name] = value;
      setFormData({
        ...formData,
        documents: updatedDocuments,
      });
      setChangedFields((prev) => ({ ...prev, documents: updatedDocuments }));
    } else if (name === "maxmDaysToBookProperty") {
      setPriceInc([]);
      let price = [];
      for (let i = 0; i < value; i++) {
        price.push(0);
      }
      setPriceInc(price);
    } else if (name === "country") {
      const selectedCountry = countries.find(country => country.commonName === value);

      if (selectedCountry) {
        try {
          let countryCurrency = await fetchCountriesCurrency(selectedCountry.countryCode);

          let currencyCode = Object.keys(countryCurrency)[0];
          let currencySymbol = countryCurrency[currencyCode].symbol;

          setFormData({
            ...formData,
            country: selectedCountry.commonName,
            countryCode: selectedCountry.countryCode,
            currencyIcon: currencySymbol,
            currencyCode: currencyCode
          });
        } catch (error) {
          console.error('Error fetching country currency:', error);
        }
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleCardImageRemove = () => {
    setFormData((prevState) => ({
      ...prevState,
      cardImage: null,
    }));
    setChangedFields((prev) => ({ ...prev, cardImage: null }));
  };

  const handleCardImageUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      cardImage: file,
    }));
    setChangedFields((prev) => ({ ...prev, cardImage: file })); 
  };

  const handleCityIconRemove = () => {
    setFormData((prevState) => ({
      ...prevState,
      cityIcon: null,
    }));
  };

  const handleCityIconUpload = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      cityIcon: file,
    }));
    setChangedFields((prev) => ({ ...prev, cityIcon: file }));
  };

  const handleAddDocument = () => {
    setFormData({
      ...formData,
      documents: [...formData.documents, { name: "", description: "" }],
    });
    setChangedFields((prev) => ({
      ...prev,
      documents: [...formData.documents, { name: "", description: "" }],
    }));
  };

  const handleDeleteDocument = (index) => {
    const updatedDocuments = [...formData.documents];
    updatedDocuments.splice(index, 1);
    setFormData({
      ...formData,
      documents: updatedDocuments,
    });
    setChangedFields((prev) => ({ ...prev, documents: updatedDocuments }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevState) => ({
      ...prevState,
      images: [...prevState.images, ...files],
    }));
    setChangedFields((prev) => ({
      ...prev,
      images: [...formData.images, ...files],
    }));
  };
  
  const handleImageRemove = (index) => {
    const updatedImages = [...formData.images];
    updatedImages.splice(index, 1);
    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages,
    }));
    setChangedFields((prev) => ({
      ...prev,
      images: updatedImages,
    }));
  };

  const handlePriceDecChange = (index, field, value) => {
    const updatedPriceDec = [...priceDec];
    updatedPriceDec[index][field] = value;
    setPriceDec(updatedPriceDec);
    setFormData(prevFormData => ({
      ...prevFormData,
      priceDec: updatedPriceDec,
    }));
    setChangedFields(prev => ({
      ...prev,
      priceDec: updatedPriceDec,
    }));
  };

  const addPriceDec = () => {
    const updatedPriceDec = [...priceDec, { month: "", percentage: "" }];
    setPriceDec(updatedPriceDec);
    setFormData(prevFormData => ({
      ...prevFormData,
      priceDec: updatedPriceDec,
    }));
    setChangedFields(prev => ({
      ...prev,
      priceDec: updatedPriceDec,
    }));
  };

  const removePriceDec = (index) => {
    const updatedPriceDec = priceDec.filter((_, i) => i !== index);
    setPriceDec(updatedPriceDec);
    setFormData(prevFormData => ({
      ...prevFormData,
      priceDec: updatedPriceDec,
    }));
    setChangedFields(prev => ({
      ...prev,
      priceDec: updatedPriceDec,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const accessToken = localStorage.getItem("x-access-token");
    const refreshToken = localStorage.getItem("x-refresh-token");

    const formDataToSend = new FormData();

    // List of fields to include in both create and update operations
    const fieldsToInclude = [
      "name", "country", "countryCode", "currencyIcon", "currencyCode", "about",
      "maxmDaysToBookProperty", "images", "cardImage", "cityIcon", "documents",
      "priceDec"
    ];

    fieldsToInclude.forEach((field) => {
      if (formData[field] !== undefined && formData[field] !== null) {
        if (field === "images" || field === "cardImage" || field === "cityIcon") {
          if (Array.isArray(formData[field])) {
            formData[field].forEach((file) => {
              formDataToSend.append(field, file);
            });
          } else {
            formDataToSend.append(field, formData[field]);
          }
        } else if (field === "priceDec" && Array.isArray(formData[field])) {
          const priceDecNumbers = formData[field].map(item => ({
            month: Number(item.month),
            percentage: Number(item.percentage)
          }));
          formDataToSend.append(field, JSON.stringify(priceDecNumbers));
        } else if (typeof formData[field] === "object") {
          formDataToSend.append(field, JSON.stringify(formData[field]));
        } else {
          formDataToSend.append(field, formData[field]);
        }
      }
    });

    if (priceInc && priceInc.length > 0) {
      const priceIncNumbers = priceInc.map((item) => Number(item));
      formDataToSend.append("priceInc", JSON.stringify(priceIncNumbers));
    }

    try {
      let response;
      if (formData.id) {
        // Updating an existing city
        // formDataToSend.append("id", formData.id);
        // let updateId = formData.id

        response = await axios.patch(`${base_url}/city?id=${formData.id}`, formDataToSend, {
          headers: {
            "x-access-token": accessToken,
            "x-refresh-token": refreshToken,
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        // Creating a new city
        response = await axios.post(`${base_url}/city`, formDataToSend, {
          headers: {
            "x-access-token": accessToken,
            "x-refresh-token": refreshToken,
            "Content-Type": "multipart/form-data",
          },
        });
      }
      if (response.status === 201 || response.status === 200) {
        if (response.status === 201) {
          alert("City Added Successfully");
        } else if (response.status === 200) {
          alert("City Updated Successfully");
        }
        setChangedFields({});
        setPriceInc([]);
        setPriceDec([{ month: "", percentage: "" }]);
        toggleFormVisibility(false);
        getCityData();
      }
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };
  const handleClear = () => {
    setFormData(initialFormData);
    setPriceInc([]);
    setPriceDec([{ month: "", percentage: "" }]);
    toggleFormVisibility(false);
  };

  const handlePriceChange = async (e, index) => {
    const { value } = e.target;
    setPriceInc((prevPriceInc) => {
      const updatedPriceInc = [...prevPriceInc];
      updatedPriceInc[index] = value;
      setChangedFields((prev) => ({
        ...prev,
        priceInc: updatedPriceInc,
      }));
  
      return updatedPriceInc;
    });
  };
  
  const handleBookChange = (e) => {
    const { name, value } = e.target;
    if (name === "maxmDaysToBookProperty") {
      setPriceInc([]);
      let price = [];
      for (let i = 0; i < parseInt(value); i++) {
        price.push(0);
      }
      setPriceInc(price);
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };


  return (
    <div style={{marginLeft: '8%'}}>
      <form className="form">
        <div className="form_input">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            className="propertyForm_input"
            placeholder="your name"
            value={formData.name}
            onChange={(e) => handleChange(e)}
            required
          />
          {formErrors.name && <span style={{ color: "red" }}>{formErrors.name}</span>}
        </div>
        <div className="form_input">
          <label htmlFor="country" style={{ marginTop: "10px" }}>
            Country:
          </label>
          <select
            id="country"
            name="country"
            value={formData.country}
            onChange={(e) => handleChange(e)}
            className="propertyForm_input"
            style={{ height: '40px' }}
            required
          >
            <option value="">Select Country</option>
            {countries.map((country, index) => (
              <option key={index} value={country.commonName}>
                {country.commonName}
              </option>
            ))}
          </select>
          {formErrors.country && <span style={{ color: "red" }}>{formErrors.country}</span>}
        </div>


        <div className="form_input">
          <div>
            <label htmlFor="images">Images:</label>
          </div>
          <div>
            <input
              type="file"
              multiple
              id="images"
              name="images"
              accept="image/*"
              onChange={handleImageUpload}
              required
            />
            <div style={{ marginTop: '10px' }}>
              {formData.images.map((image, index) => (
                <div key={index} style={{ display: 'inline-block', position: 'relative', }}>
                  <img
                    src={typeof image === "string" ? image : URL.createObjectURL(image)}
                    alt={`Imagecity ${index}`}
                    style={{
                      maxWidth: "140px",
                      maxHeight: "140px",
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => handleImageRemove(index)}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      background: 'red',
                      color: 'red',
                      border: '1px solid red',
                      backgroundColor: 'white',
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      aspectRatio: '1/1',
                    }}
                  >
                    X
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="form_input">
          <div>
            <label htmlFor="cardImage">Card Image:</label>
          </div>
          <div>
            <input
              type="file"
              id="cardImage"
              name="cardImage"

              accept="image/*"
              onChange={handleCardImageUpload}
            />
            {formData.cardImage && (
              <div style={{ display: 'inline-block', position: 'relative', margin: '5px' }}>
                <img
                  src={typeof formData.cardImage === 'string' ? formData.cardImage : URL.createObjectURL(formData?.cardImage)}
                  alt="CardImage"
                  style={{
                    maxWidth: "140px",
                    maxHeight: "140px",
                  }}
                />
                <button
                  type="button"
                  onClick={handleCardImageRemove}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    backgroundColor: 'white',
                    border: '1px solid red',
                    color: 'red',

                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    aspectRatio: '1/1',
                  }}
                >
                  X
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="form_input">
          <div>
            <label htmlFor="cityIcon">City Icon:</label>
          </div>
          <div>
            <input
              type="file"
              id="cityIcon"
              name="cityIcon"

              accept="image/*"
              onChange={handleCityIconUpload}
            />
            {formData.cityIcon && (
              <div style={{ display: 'inline-block', position: 'relative', margin: '5px' }}>
                <img
                  src={typeof formData.cityIcon === 'string' ? formData.cityIcon : URL.createObjectURL(formData?.cityIcon)}
                  alt="CardImage"
                  style={{
                    maxWidth: "140px",
                    maxHeight: "140px",
                  }}
                />
                <button
                  type="button"
                  onClick={handleCityIconRemove}
                  style={{
                    position: 'absolute',
                    top: '-5px',
                    left: '15px',
                    backgroundColor: 'white',
                    border: '1px solid red',
                    color: 'red',

                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    aspectRatio: '1/1',
                  }}
                >
                  X
                </button>
              </div>
            )}
          </div>
        </div>
        {formData.documents.map((doc, index) => (
          <div key={index} className="form">
            <div className="form_input">
              <label htmlFor={`document_name_${index}`}>Document Name:</label>
              <input
                type="text"
                id={`document_name_${index}`}
                className="propertyForm_input"
                name="name"
                placeholder="Name"
                value={doc.name}
                onChange={(e) => handleChange(e, index)}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor={`document_des_${index}`} >
                Description:
              </label>
              <input
                type="text"
                id={`document_des_${index}`}
                name="description"
                placeholder="Description"
                className="propertyForm_input"
                value={doc.description}
                onChange={(e) => handleChange(e, index)}
                required
              />
              {index === formData.documents.length - 1 && (
                <>
                  <img
                    src={Plus}
                    alt="plus sign"
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={handleAddDocument}
                  />
                  {index !== 0 && (
                    <img
                      src={Minus}
                      alt="minus sign"
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteDocument(index)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
        <div className="form_input">
          <div>
            <label htmlFor="about">About:</label>
          </div>
          <div>
            <textarea
              style={{ height: "150px", width: "600px" }}
              id="about"
              name="about"
              className="propertyForm_input"
              value={formData.about || ""}
              onChange={(e) => handleChange(e)}
              required
            />
            {formErrors.about && <span style={{ color: "red" }}>{formErrors.about}</span>}
          </div>
        </div>

        <div className="form_input">
          <label htmlFor="priceDec">Discount:</label>
          {priceDec.map((item, index) => (
            <div key={index} className="flex items-center gap-4">
              <input
                type="number"
                placeholder="Percentage"
                value={item.percentage}
                onChange={(e) => handlePriceDecChange(index, 'percentage', e.target.value)}
                className="propertyForm_input"
              />
              <input
                type="number"
                placeholder="Month"
                value={item.month}
                onChange={(e) => handlePriceDecChange(index, 'month', e.target.value)}
                className="propertyForm_input"
              />
              <button type="button" onClick={() => removePriceDec(index)}>Remove</button>
            </div>
          ))}
          <button type="button" onClick={addPriceDec}>Add Discount</button>
        </div>

        <div className="form_input">
          <label htmlFor="maxmDaysToBookProperty">Available to Book (No. of Days):</label>
          <input
            type="number"
            id="maxmDaysToBookProperty"
            name="maxmDaysToBookProperty"
            className="propertyForm_input"
            placeholder="Enter number of days"
            value={formData.maxmDaysToBookProperty}
            onChange={handleBookChange}
            required
          />
        </div>
        {priceInc.map((price, index) => (
          <div key={index} className="form_input">
            <label htmlFor={`price_inc_${index}`}>Percent of Increase Per Day: {index + 1}:</label>
            <input
              type="number"
              id={`price_inc_${index}`}
              name="price_inc"
              className="propertyForm_input"
              placeholder="Price increase for the day"
              value={price}
              onChange={(e) => handlePriceChange(e, index)}
              required
            />
          </div>
        ))}

      </form>
      <div>
        <button className="submit_city" type="submit" onClick={handleSubmit}>
          Submit
        </button>
        <button className="cancel_city" type="button" onClick={handleClear}>
          Cancel
        </button>
      </div>
    </div>

  );
};

export default CityForm;
