import React, { useEffect, useState } from "react";
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";

import axios from "axios";
import { base_url } from "../../constant";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content:end;
  height: 100vh;
`;

const SidebarContainer = styled.div`
  width: 300px;
  background-color: #f0f0f0;
  padding: 20px;
`;

const Content = styled.div`
  width:80%;
  padding: 20px;
`;


const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
`;

const Card = styled.div`
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:start;
  border-radius: 12px;
`;




const TalkToUs = () => {


  const [talkData, setTalkData] = useState([]);

  const getTalkData = async () => {
    try {
      const res = await axios.get(`${base_url}/enquiry/about`);
      console.log(res?.data?.data, "Data");
      setTalkData(res?.data?.data);
    } catch (error) {
      console.log(error, "Error");
    }
  };

  useEffect(() => {
    getTalkData();
  }, []);



  const renderTalkCards = () => {
    return talkData.map((data) => (
      <Card key={data.id}>
        <h3>name : {data.name}</h3>
        <p>email : {data.email}</p>
        <p>phone: {data.phone}</p>

      </Card>
    ));
  };

  return (
    <Container>
      <SidebarContainer>
        <SideBar />
      </SidebarContainer>
      <Content>
        <FirstNavbar />
        
        <CardContainer>{renderTalkCards()}</CardContainer>
      </Content>
    </Container>
  );
};

export default TalkToUs;