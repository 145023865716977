import React, { useEffect, useState } from 'react'
import FirstNavbar from "../dashboard/FirstNavbar";
import SideBar from "../dashboard/SideBar";
import UniversityForm from './universityForm';
import { base_url } from '../../constant';
import styled from "styled-components";
import axios from 'axios';

const Card = styled.div`
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  display:flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:start;
  border-radius: 12px;
`;
const Button2 = styled.button`
  
  margin-bottom:20px;
  border: none;
  padding: 8px ;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background:none;
  &:hover {
   transform : scale(1.1);
  }
`;

const Button = styled.button`
  background-color: #0023B4;
  color: #fff;
  margin-bottom:20px;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const University = () => {
  const [showForm, setShowForm] = useState(false);
  const [universityData, setUniversityData] = useState([]);
  const [editingUniversity, setEditingUniversity] = useState(null);

  // Mapping function to render booking cards
  const renderBookingCards = () => {
    return universityData.map((university) => (
      <Card key={university.id}  >
        <h3>Name: {university.name}</h3>
        <p>City: {university.city}</p>
        <p>Country: {university.country}</p>
        <div >
          <Button2 ><img src="/edit.svg"  alt="edit"  onClick={() => handleEditUniversity(university)}/></Button2>
          <Button2 style={{marginLeft:'30px'}}><img src="/trash-2 (1).svg" alt="delete"  onClick={() => handleDeleteUniversity(university.id)}/></Button2>
        </div>
      </Card>
    ));
  };

  // Function to toggle the visibility of the form
  const toggleFormVisibility = () => {
    setShowForm(!showForm);
    setEditingUniversity(null);
  };

  const getUniversityData = async () => {
    try {
      const res = await axios.get(`${base_url}/university`)
      setUniversityData(res?.data?.data)
    } catch (error) {
      console.log(error?.response)
    }
  }

  const handleDeleteUniversity= async (id) => {
    try {
      const res = await axios.delete(`${base_url}/university?id=${id}`);
      if(res.data.data){
        getUniversityData();
      }
      
    } catch (error) {
      console.log('error while deleting university', error);
    }
  }

  const handleEditUniversity = (university) => {
    setEditingUniversity(university);
    setShowForm(true);
  };

  useEffect(() => {
    getUniversityData();
  }, [])

  return (
    <div className='mainContainer'>
      <div className="sidebar">
        <SideBar />
      </div>
      <div className="content" style={{width:'77%'}}>
        <div className="container">
          <FirstNavbar />
          {!showForm && <Button onClick={toggleFormVisibility}>
            Add University
          </Button>}
          {/* Conditional rendering of the form */}
          {showForm && (
            <UniversityForm
              university={editingUniversity}
              onSubmitSuccess={getUniversityData}
              toggleFormVisibility={toggleFormVisibility}
            />
          )}
         {!showForm && <div className="booking-cards-container">
            {renderBookingCards()}
          </div>}
        </div>
      </div>
    </div>
  )
}

export default University